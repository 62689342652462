@import "../../../styles/app.scss";

.seity-alert {
  max-width: rem(500);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: rem(10);
  }
  &__title {
    font-size: rem(22);
    line-height: rem(24);
    color: $seity-green;
    margin-top: rem(5) !important;
    @include font-base($font-source-sans, Bold);
  }
  &__subtitle {
    font-size: rem(15);
    line-height: rem(24);
    text-align: center;
    color: $seity-green;
    word-break: break-word;
    margin: rem(15) rem(20) rem(20) !important;
    white-space: pre-wrap;
    @include font-base($font-source-sans, Bold);
  }
}