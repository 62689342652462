@import "src/styles/app.scss";

.survey-switch-container {
  display: flex;
  align-items: center;

  span {
    @include font-base($font-source-sans, Normal);
    color: $seity-green;
    margin-left: rem(24);
  }
}
