@import "../../../styles/app.scss";

.filters-wrap {
  margin: rem(5) rem(10);
}

.report-style-class {
	width: 100%;
  background-color: $seity-cream;
  padding-bottom: rem(100);
  @include respond-to(desktop) {
    height: 100%;
  }
  .displayAreaViewport {
    background-color: $seity-cream;
  }
  iframe {
    border: none;
    height: calc(476vw - 571px) !important;
    @include respond-to(desktop) {
      height: 90vw !important;
    }
  }
}

.hide-report {
  visibility: hidden;
}
