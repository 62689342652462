@import '../../../styles/app.scss';

.reset-password-NumberInput {
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(56);
    padding: rem(16);
    background-color: white;
    border: rem(1) solid $survey-gray;
    border-radius: rem(8);
    &.focus {
      border: rem(2) solid #67ACAF;
    }
    &.error {
      border: rem(2) solid $seity-orange;
    }
    > input {
      border: none;
      width: 100%;
      font: 400 rem(16) $font-source-sans;
      line-height: rem(22);
      &:focus {
        outline: none
      }
    }
    > span {
      cursor: pointer;
      color: #4F7E81;
      font: 600 rem(16) $font-source-sans;
    }
  }
  &__error {
    display: flex;
    margin-top: rem(8);
    &.error {
      span {
        color: $seity-orange !important;
        &:first-child {
          display: inline;
        }
      }
    }
    span {
      font: 400 rem(16) $font-source-sans;
      color: $seity-light-black;
      &:last-child {
        margin-left: auto;
      }
      &:first-child {
        display: none;
      }
    }
  }

}
