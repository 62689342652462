@import "src/styles/app.scss";

.survey-item-menu-wrapper {
  position: absolute;
  right: rem(-32);
  top: rem(50);
  width: rem(180);
  background: $seity-green;
  border-radius: rem(5);
  padding: rem(5);
  z-index: 9;

  li {
    list-style: none;
    padding: rem(5);
    cursor: pointer;
    color: $seity-white;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      margin-right: 8px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
}
