@import "../../../styles/app.scss";

.breadcrumbs-container {
    flex: 1;
    display: flex;
    margin: 0 rem(20);
    min-height: rem(62);
    overflow: hidden;
    border-top-right-radius: rem(30);

    .wrapper-class {
        // height: rem(62);
        // width: 100%;
        // display: inline-block;
        overflow: hidden;
    }
    .wrapper-class__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: rem(40);
        height: 100%;
        border-bottom-left-radius: rem(30);
        white-space: nowrap;
    }
    span {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
    .school-wrap {
        flex: 1;
        @extend .wrapper-class;
        &__content {
            background-color: #dedbd3;
            min-width: rem(100);
            white-space: nowrap;
            @extend .wrapper-class__content;
        }
    }
    .teacher-wrap {
        background-color: #dedbd3;
        width: rem(196);
        @extend .wrapper-class;
        &__content {
            background-color: #ceccc6;
            @extend .wrapper-class__content;
        }
    }
    .class-wrap {
        background-color: #ceccc6;
        width: rem(250);
        @extend .wrapper-class;
        &__content {
            @extend .wrapper-class__content;
            background-color: #333332;
        }
        span {
            color: $seity-cream !important;
        }
    }
    .title {
        @include font-base($font-source-sans, Bold);
        font-size: rem(14);
        color: $seity-dark-green;
    }
    .desc {
        @include font-base($font-source-sans, Bold);
        font-size: rem(18);
        color: $seity-dark-green;
    }
    .no-background {
        background-color: transparent;
        width: rem(300);
    }
    .top-right-radius {
        border-top-right-radius: rem(30);
    }
}
