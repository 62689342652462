@import "../../../styles/app.scss";

.survey-modal-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
}

.survey-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $seity-cream;
  border-radius: rem(5);

  transition: transform 0.6s;
  transform-style: preserve-3d;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;
  max-width: rem(968);
  height: 90%;
  max-height: rem(908);
  z-index: 100;

  .button-close {
    position: absolute;
    right: rem(-36);
    top: rem(-17);
    z-index: 101;
  }
}
.survey-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 68, 63, 0.6);
  z-index: 99;
}
