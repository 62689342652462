@import "../../../../styles/app.scss";

.survey-modal-scheduler-title {
  @include font-base($font-source-sans, Normal);
  font-size: rem(40);
  line-height: rem(40);
  color: $seity-dark-green;
  margin-bottom: rem(32);
}

.survey-audience-wrapper {
  width: 100%;
  max-width: rem(1158);
  display: flex;
  flex-direction: column;
  height: 100%;
  .audience-type-wrap {
    display: flex;
    margin-top: rem(64);
    a {
      &:first-child {
        margin-right: rem(43);
      }
    }
  }
  .question-content {
    width: 100%;
    padding: rem(40) 0;
    flex: 1;
    overflow-y: none !important;
    // -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    list-style: none;
  }
  .modal-footer-container {
    margin-top: 30px;
    p {
      @include font-base($font-source-sans, Normal);
      margin-bottom: rem(24);
      font-size: rem(16);
      color: $seity-green;
      line-height: rem(20);
    }
  }

  .survey-input-inline {
    display: flex;
    align-items: flex-end;

    .input-days {
      display: flex;
      align-items: center;
      margin-right: rem(20);
      span {
        margin-left: rem(10);
      }
    }
    .select-repeat {
      width: rem(242);
    }

    .every-input-form {
      display: flex;
      align-items: center;

      span {
        color: $seity-green;
        margin-right: rem(23);
        margin-left: rem(25);
      }

      .survey-frequency {
        margin-left: rem(10);
      }
      .select-every {
        width: rem(68);
      }
    }
  }
  .survey-input-class-base {
    margin: 0 rem(16) 0 0 !important;
  }
  .select-day-of-week {
    width: 36.5%;
  }
  .input-spacing {
    margin-top: rem(23);
  }

  .survey-checkbox-group {
    span {
      @include font-base($font-source-sans, Normal);
      color: $seity-green;
      margin-bottom: rem(5);
      width: 100%;
    }
  }

  .question-footer {
    display: flex;
    padding: rem(24) rem(104);
    width: 100%;
    height: rem(98);
    border-top: 2px solid rgba(0, 0, 0, 0.35);
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $seity-cream;
    .back-button {
      margin-right: auto;
    }
  }
}

.survey-selectstaff {
  width: 100%;
  .org-wide {
    display: flex;
    align-items: center;
    height: rem(67);
    width: 100%;
    a {
      display: flex;
      align-items: center;
    }
    img {
      margin-left: rem(19);
      width: rem(18);
      height: rem(19);
    }
    h5 {
      margin-left: rem(10);
      @include font-base($font-source-sans, Normal, rem(18));
      color: $survey-black;
      margin-top: rem(-2);
    }
    span {
      margin-left: rem(8);
      @include font-base($font-source-sans, Normal, rem(12));
      color: #505860;
    }
  }
  &__content {
    display: flex;
    width: 100%;
    margin-top: rem(42.5);
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    .listbox-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: rem(53);
      > div {
        display: flex;
        width: 100%;
        .survey-audiencelist {
          flex: 1;
        }
        .survey-audiencelist:first-child {
          margin-right: rem(12);
        }
        .survey-audiencelist:last-child {
          margin-left: rem(12);
        }
        &:last-child {
          margin-top: rem(43);
        }
      }
    }
    .selected-users-wrap {
      width: rem(346);
    }
  }
  .survey-audiencelist {
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: rem(5);
      > span {
        @include font-base($font-source-sans, Normal, rem(18));
        color: $survey-black;
      }
      > span:first-child {
        margin-right: auto;
      }
      > span:last-child {
        font-size: rem(14);
      }
      a > span {
        padding-left: rem(20);
        @include font-base($font-source-sans, Bold, rem(14));
        color: $seity-world;
      }
    }
    .survey-audience-result-panel {
      border: rem(1) solid $survey-gray;
      background-color: white;
      border-radius: rem(8);
    }
    .survey-audience-panel {
      height: rem(400);
    }
    .survey-audience-result-panel {
      height: rem(843 + 28);
    }
    ul {
      padding: 0 rem(10.7);
      overflow-y: scroll;
      li {
        display: flex;
        align-items: center;
        padding: 0 rem(9);
        height: rem(56);
        border-bottom: rem(1) solid $survey-gray;
        &:last-child {
          border-bottom: 0;
        }
        a {
          display: flex;
          align-items: center;
        }
        h5 {
          margin-left: rem(10);
          @include font-base($font-source-sans, Normal, rem(15));
          color: $survey-black;
          margin-top: rem(-2);
        }
        span {
          margin-left: rem(8);
          @include font-base($font-source-sans, Normal, rem(12));
          color: #505860;
        }
      }
    }
  }
  .audience-result {
    height: 100%;
    ul {
      background-color: #FAFAFA;
      > p {
        text-align: center;
        @include font-base($font-source-sans, Normal, rem(14));
        color: black;
        margin-top: rem(24);
      }
    }
  }
}
