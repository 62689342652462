@import "../../../styles/app.scss";

.survey-confirm-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
}

.survey-confirm {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $seity-cream;
  border-radius: rem(5);

  transition: transform 0.6s;
  transform-style: preserve-3d;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: rem(400);
  max-width: rem(968);
  height: rem(270);
  padding: rem(30);
  max-height: rem(908);
  z-index: 100;
  &__button-wrap {
    display: flex;
    margin-top: auto;
    button {
      &:first-child {
        margin-right: rem(15);
      }  
    }
  }
  h3 {
    @include font-base($font-source-sans, Normal, rem(32));
    color: $seity-dark-green;
    margin-bottom: rem(20);
  }
  p {
    @include font-base($font-source-sans, Normal, rem(24));
    color: $seity-dark-green;
  }
}
.survey-confirm-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(70, 68, 63, 0.6);
  z-index: 99;
}
