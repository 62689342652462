@import '../../../styles/app.scss';

.fp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: $seity-dark-green;
  padding: rem(10);
  h1 {
    margin: rem(40) 0 rem(45) 0;
    @include font-base($font-source-serif, Normal, rem(40));
  }
  h5 {
    margin: rem(5) 0 0 0;
    @include font-base($font-source-sans, Normal, rem(16));
    font-style: italic;
  }
  p {
    color: $seity-dark-green;
    width: rem(580);
    @include respond-to(mobile) {
      width: 100%;
    }
  }
}

.fp-email {
  margin: rem(60) 0 rem(100) 0;
  width: rem(300);
  h3 {
    @include font-base($font-source-sans, Normal, rem(17));
    text-align: left;
    margin: 0 0 rem(10) rem(12);
  }
}

.fp-response-error {
  height: rem(50);
  @include font-base($font-source-sans, Normal, rem(13));
  &-error {
    color: $seity-orange;
  }
}

.fp-buttons {
  display: flex;
  width: rem(250);
  justify-content: space-between;
}

.fp-succuess-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(20);
  p {
    margin-bottom: rem(60) !important;
    margin-top: rem(20) !important;
    color: $seity-dark-green;
  }
}