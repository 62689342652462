@import "../../styles/app.scss";

.wbn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #FBF9F7;
  > h1 {
    font: 600 rem(42) $font-source-serif;
    color: $seity-light-black;
    margin: rem(32) 0 rem(32) rem(40);
  }
  &-main {
    width: 100%;
    border-top: rem(1) solid #e4e4e4;
    display: flex;
  }
  &-left {
    width: rem(294);
    height: calc(100vh - 200px);
    overflow-y: auto;
    scrollbar-color: #E4E4E4 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar-thumb {
      background: #E4E4E4;
      border-radius: rem(3);
    }
    &::-webkit-scrollbar {
      width: rem(6);
    }
    &.hangon {
      position: fixed;
      top: 0;
      left: 0;
      height: calc(100vh - 100px);
      @include main-margin-top;
    }
    > .wbn-savedViews-wrap {
      margin: rem(40) 0 rem(30) rem(40);
      > button { 
        width: rem(214);
      }
    }
    &__filters {
      width: 100%;
      border-top: rem(1) solid #e4e4e4;
      > p {
        margin: rem(40) 0 rem(8) rem(40);
        font: 600 rem(14) $font-source-sans;
        color: $seity-light-black;
      }
    }
  }
  &-body {
    flex: 1;
    border-left: rem(1) solid #e4e4e4;
    padding: rem(40);
    width: calc(100% - 294px);
    // height: calc(100vh - 100px);
    // overflow-y: auto;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    &.hangon {
      margin-left: rem(294);
      @include main-margin-top;
    }
    &__buttons {
      margin-top: rem(24);
      display: flex;
      width: 100%;
      height: rem(40);
      .btn-columns {
        width: rem(166);
      }
      &-columns {
        margin-left: auto;
        margin-right: rem(16);
      }
    }
    &__savedView-header {
      display: flex;
      margin-bottom: rem(24);
      > h3 {
        font: 600 rem(26) $font-source-sans;
        color: $seity-light-black;
        margin-right: auto;
      }
      > button {
        background-color: unset;
        border: none;
        cursor: pointer;
      }
    }
  }
}
