@import "../../../../styles/app.scss";

.wbn-tooltip-listitem-container {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  .wbn-tooltip-icon-24 {
    width: rem(24);
    height: rem(24);
    margin-right: rem(16);
  }
  
  .wbn-tooltip-icon-32 {
    width: rem(32);
    height: rem(32);
    margin-right: rem(16);
  }

  .wbn-tooltip-listitem-text {
    font: 400 rem(16) $font-source-sans;
    margin: 0;
    max-width: rem(314);
    text-align: start;
    width: 100%;
  }
}

.wbn-tooltip-itemalign-center {
  align-items: center;
  p {
    text-align: center !important;
  }
}

.wbn-tooltip-itemalign-flex-start {
  align-items: flex-start;
}