@import "../../../styles/app.scss";

.wbn-disabled-overlay {
  background-color: rgba(0, 0, 0, 0);
}

.wbn-button-menu-modal {
  margin-top: rem(12);
  min-width: rem(200);
  max-height: rem(603);
  transform: none;
  > .wbn-modal-children-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    > .wbn-modal-btn-wrap {
      display: flex;
      flex-direction: row;
      margin-top: auto;
      > .wbn-modal-button {
        width: 80px;
        height: 52px;
        padding: 0px;
        border-radius: 8px;
        transition: opacity 0.3s ease;
      }
    }
    > .wbn-modal-content-wrap {
      width: 100%;
      margin: rem(24) 0;
      display: flex;
      flex-direction: column;
      gap: rem(24);
      overflow-y: auto;
      scrollbar-color: #E4E4E4 transparent;
      scrollbar-width: thin;
      &::-webkit-scrollbar-thumb {
        background: #E4E4E4;
        border-radius: rem(1);
      }
      &::-webkit-scrollbar {
        width: rem(4);
      }
      > h3 {
        color: $seity-light-black;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
      }
    
      > div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}
