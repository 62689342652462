@import '../../../styles/app.scss';

.wbn-searchInput {
  display: flex;
  align-items: center;
  width: 100%;
  height: rem(56);
  padding: rem(16);
  background-color: white;
  border: rem(1) solid $survey-gray;
  border-radius: rem(8);
  &.focus {
    border: rem(2) solid #67ACAF;
  }
  > input {
    margin-left: rem(11);
    border: none;
    width: 100%;
    font: 400 rem(16) $font-source-sans;
    line-height: rem(22);
    &:focus {
      outline: none
    }
  }
  > span {
    cursor: pointer;
    color: #4F7E81;
    font: 600 rem(16) $font-source-sans;
  }
}
