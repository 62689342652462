@import '../../../../../styles/app.scss';

.survey-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: rem(2) 0;
  .question-content {
    padding: rem(82.5) 0 rem(30);
    flex: 1;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    list-style: none;
    li {
      margin-bottom: rem(32);
      color: $seity-dark-green;
      @include font-base($font-source-sans, Normal);
      &:first-child {
        span {
          font-size: rem(18);
          line-height: rem(24);
          display: block;
          line-break: anywhere;
        }
        h1 {
          @include font-base($font-source-sans, Bold);
          font-size: rem(40);
          line-height: rem(40);
          margin-top: rem(15);
          margin-bottom: rem(24);
        }
      }
      &:last-child {
        margin-bottom: rem(24);
      }
    }
    .progressbar-container {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(18);
      .progressbar-wrap {
        height: rem(8.2);
        width: rem(500);
        border-radius: rem(2);
        background-color: $seity-grey;
        margin-right: rem(10);
        .progressbar-track {
          width: rem(83);
          height: 100%;
          border-radius: rem(2);
          background-color: $seity-dark-green;
        }
      }
    }
  }
  .question-footer {
    padding: rem(24) rem(104);
    width: 100%;
    height: rem(98);
    text-align: center;
    border-top: 2px solid rgba(0, 0, 0, 0.35);
  }
}

.survey-preview-question {
  border: rem(2) solid $seity-dark-green;
  border-bottom-left-radius: rem(25);
  border-top-right-radius: rem(25);
  padding: rem(19) rem(25);
}