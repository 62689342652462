@import "../../styles/app.scss";

.reset-password {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FBF9F7;

  .reset-password__btn {
    height: rem(55);
    margin-top: rem(18);
    width: rem(200);
  }

  .reset-password__searchTitle {
    padding: rem(40);
    padding-bottom: 0;
    font: 600 rem(26) $font-source-serif;
    color: $seity-light-black;
  }

  .reset-password__bubble-count {
    width: 24px;
    height: 24px;
    position: absolute;
    padding: 4px;
    border-radius: 100px;
    background-color: $survey-black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    top: rem(-10);
    right: 0;
  }

  > h1 {
    font: 600 rem(42) $font-source-serif;
    color: $seity-light-black;
    margin: rem(32) 0 rem(32) rem(40);
  }
  .reset-password__main {
    width: 100%;
    border-top: rem(1) solid #e4e4e4;
  }

  .reset-password__top {
    padding: rem(40);
    padding-bottom: 0;
    display: flex;
    justify-content: space-between; /* This will space out the children equally */

    &.hangon {
      position: fixed;
      top: 0;
      left: 0;
      height: calc(100vh - 100px);
      @include main-margin-top;
    }

    .reset-password__filter {
      flex: 1; /* This makes each filter flex item grow equally */
      margin-right: rem(20);

      /* Remove the margin for the last filter item */
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .reset-password__body {
    border-left: rem(1) solid #e4e4e4;
    padding: rem(40);
    &.hangon {
      margin-left: rem(294);
      @include main-margin-top;
    }
    &__buttons {
      margin-top: rem(24);
      display: flex;
      width: 100%;
      height: rem(40);
      .btn-columns {
        width: rem(166);
      }
      &-columns {
        margin-left: auto;
        margin-right: rem(16);
      }
    }
  }
}
