@import "../../../styles/app.scss";

.wbn-datepicker {
  padding-top: rem(24);
  padding-bottom: rem(24);
  &__from-date {
    margin-bottom: rem(24);
  }
  &__date-input {
    background-color: white;
    border: 1px solid $survey-gray;
    border-radius: 8px;
    padding: rem(15) rem(18) rem(15) rem(18);
    display: flex;
    align-items: center;
    margin-top: rem(8);
    > img {
      margin-right: rem(10);
    }
  }
  &__input {
    background-color: transparent;
    border-width: 0;
    color: $seity-light-black;
    font: 600 rem(16) $font-source-sans;
  }
  &__title {
    color: $seity-light-black;
    font: 400 rem(18) $font-source-sans;
    width: 100%;
  }
}
