@import "../../../styles/app.scss";

.wbn-filter-chip {
  height: rem(28);
  background-color: $seity-spirit;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: rem(6) rem(10) rem(6) rem(10);
  border-radius: rem(5);
  margin: rem(12) rem(10) rem(12) rem(0);
  p {
    color: $seity-black;
    font: 600 rem(12) / rem(16) $font-source-sans;
    display: flex;
    align-items: center;
    span {
      width: rem(21);
      height: rem(21);
      margin-left: rem(6);
      background-color: #E1F6F7;
      border-radius: rem(14);
      font-size: rem(10);
      line-height: rem(21);
      text-align: center;
    }
  }
  img {
    height: rem(16);
    width: rem(16);
    margin-left: rem(10);
    cursor: pointer;
  }
}

.wbn-filter-options {
  display: flex;
  flex-wrap: wrap;
  &__show-all {
    display: flex;
    padding: rem(6) rem(10) rem(6) rem(10);
    margin: rem(12) rem(10) rem(12) rem(0);
    cursor: pointer;
    align-items: center;
    p {
      color: $survey-teal-dark;
      font: 600 rem(16) / rem(20) $font-source-sans;
    }
    img {
      margin-left: rem(10);
      height: rem(7);
      width: rem(14);
    }
  }
}
