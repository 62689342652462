@import "../../../styles/app.scss";

.survey-sidemenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: rem(45);
  ul {
    width: 100%;
    margin-bottom: rem(46);
  }
  h1 {
    @include font-base($font-source-serif, Normal);
    font-size: rem(40);
    line-height: rem(46);
    width: 100%;
    height: rem(123);
    padding-top: rem(56);
    color: $survey-grey02100;
    text-align: center;
    border-bottom: solid rem(2) $survey-bg;
  }
}
.sidemenu-item {
  background-color: $seity-cream;
  border-bottom: solid rem(2) $survey-bg;
  padding-left: rem(50);
  padding-right: rem(50);
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
  &.active {
    background-color: $survey-bg;
  }
  &__desc {
    padding-left: rem(32);
    .desc-title {
      @include font-base($font-source-sans, Bold);
      font-size: rem(20);
      color: $survey-grey02100;
    }
    .desc-subtitle {
      @include font-base($font-source-sans, Light);
      font-size: rem(16);
      color: $survey-grey02100;
    }
  }
  img {
    width: rem(48);
    height: rem(48);
    @include respond-to(small-desktop) {
      width: rem(40);
      height: rem(40);
    }
  }
}
