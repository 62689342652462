@import '../../styles/app.scss';

.sr-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $seity-white;
  border-radius: rem(10);
  padding: rem(24) rem(40) rem(32) rem(40);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: rem(200);
  min-height: rem(200);
  z-index: 20;
}

.sr-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.sr-wrapper {
  padding: rem(20) 0;
  width: 100%;
}

.sr-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font: bold rem(27) $font-source-dm-seif;
  margin-bottom: rem(32);

  &-title {
    margin-right: rem(130);
  }
}

.sr-close-btn {
  border: none;
  background-color: $seity-white;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.sr-main {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: rem(20) $font-source-sans;

  &-actions {
    width: rem(440);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: rem(32);
  }

  &-error {
    color: $seity-orange;
    margin-top: rem(10);
  }

  &-loader {
    width: rem(60);
  }
}

.sr-report-btn {
  height: rem(84);
  width: rem(200);
  background-color: $seity-teal;
  border: none;
  font: bold rem(18) $font-source-sans;
  border-radius: rem(10);
  color: $seity-white;
  margin: rem(10) rem(10);

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}