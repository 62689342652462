@import "../../../styles/app.scss";

.seity-input {
  width: 100%;
  height: 100%;
  border-radius: rem(36);
  background-color: $seity-cream;
  border: rem(1.5) solid $seity-green;
  padding: 0 rem(14);
  outline: none;
  @include font-base($font-source-sans, Normal, rem(18));
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 247, 250, 0.25);
  }
  &-wrapper {
    position: relative;
    width: 100%;
    height: rem(48);
  }
  &-padding-left {
    padding-left: rem(30);
  }
  &-padding-right {
    padding-right: rem(45);
  }
  &-left-icon {
    position: absolute;
    left: rem(10);
    bottom: rem(16);
  }
  &-right-icon {
    position: absolute;
    width: rem(22);
    right: rem(15);
    bottom: rem(16);
  }
  &-copy-clipboard {
    position: absolute;
    top: 50%;
    right: rem(15);
    transform: translateY(-50%);
  }
  &-error {
    border: rem(1.5) solid $seity-orange;
  }
}

.seity-input-with-label {
  margin: rem(10) 0 rem(10) 0;
  label {
    @include font-base($font-source-sans, Normal, rem(18));
    color: $seity-dark-green;
    padding: 0 0 0 rem(12);
  }
}
