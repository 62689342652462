@import '../../../styles/app.scss';

.analytic-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $seity-cream;
  border: solid $seity-dark-green rem(1);
  border-radius: rem(24);
  padding: rem(20) 0;
  max-height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: rem(309);
  min-height: rem(200);
  z-index: 20;
}
.analytic-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(254, 248, 234, 0.35);
}
.analytic-modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  a {
    margin-top: rem(-5);
    margin-left: auto;
    width: 100%;
    text-align: right;
  }
  h2 {
    color: $seity-cream;
  }
}
