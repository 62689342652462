@import "../../styles/app.scss";

.survey-report {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 rem(10);
  padding: rem(20) 0;
}
