@import "src/styles/app.scss";

.survey-select-container {
  display: flex;
  flex-direction: column;
  margin: rem(10);
  font-size: rem(16);
  &__top {
    display: flex;
    align-items: center;
    label {
      @include font-base($font-source-sans, Normal);
      color: $seity-green;
      margin-bottom: rem(5);
    }
    a {
      margin-left: rem(10);
    }
    &-button {
      margin-left: auto;
    }
  }
  .survey-child-select {
    margin: 0 !important;
  }
}

.select-audience{
  width: 100%;
}