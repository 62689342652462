@import "../../../styles/app.scss";

.ats-brand-colors-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: rem(150);
  &__circle {
    width: rem(100);
    height: rem(100);
    border-radius: 100%;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  }

  &__color-name,
  &__color-hex {
    @include font-base($font-source-sans, Bold, rem(24));
    color: $seity-dark-green;
    margin-top: rem(16);
  }

  &__color-rgb {
    @include font-base($font-source-sans, Light, rem(18));
    color: $seity-dark-green;
    margin-top: rem(16);
  }
}
