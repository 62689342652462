@import "../../../styles/app.scss";

.ats-brandoverview {
  display: flex;
  flex-direction: column;
  h2 {
    color: $seity-dark-green;
    @include font-base($font-source-serif, Bold, rem(36));
  }
  span {
    color: $survey-grey02100;
    line-height: rem(32);
    margin-bottom: rem(64);
    @include font-base($font-source-sans, Normal, rem(24));
  }
  &__underline {
    width: rem(32);
    height: rem(2);
    background-color: $seity-dark-green;
    margin: rem(30) 0;
  }
}
