@import "../../../styles/app.scss";

.wbn-disabled-overlay {
  background-color: rgba(0, 0, 0, 0);
}

.wbn-modal {
  width: rem(485);
  min-height: rem(300);
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
  }
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    > h3 {
      color: $seity-light-black;
      font: 400 rem(16) $font-source-sans;
    }
    > button {
      margin-left: auto;
      background-color: unset;
      padding: 0;
      border: none;
      cursor: pointer;
    }
  }
  &__content {
    margin: rem(24) 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-bottom: rem(1) solid #E4E4E4;
    padding-bottom: rem(32);
    &::-webkit-scrollbar {
      display: none;
    }
    > h3 {
      color: $seity-light-black;
      font: 400 rem(16) $font-source-sans;
      margin-bottom: rem(8);
    }
    > p {
      color: black;
      font: 400 rem(16) $font-source-sans;
    }
  }
  &__btn-wrap {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    > .wbn-modal-button {
      height: 52px;
      border-radius: 8px;
      transition: opacity 0.3s ease;
    }
    > button {
      &:first-child {
        padding: 0 rem(32);
      }
      &:last-child {
        padding: 0px;
        width: rem(147);
      }
    }
  }
  .seity-modal__btnWrap {
    button {
      font-size: rem(16);
      &.seity-button__plain-text {
        padding: 0;
        margin-right: rem(24);
      }
    }
  }
}
