.seity-primary-cancel-nav-container {
    flex-direction: row; 
    justify-content: center;
    text-align: center;
}

.seity-primary-cancel-nav-container-error {
    text-align: center;
    color: red;
    height: 2em;
}

.seity-primary-cancel-nav-container .btn {
    margin-right: 5px !important;
    margin-left: 5px !important;
}