@font-face {
    font-family: 'Source Sans Pro Normal';
    src: url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Source Sans Pro Light';
    src: url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Serif Pro Normal'; 
    src: url('../assets/fonts/SourceSerifPro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Source Serif Pro Bold';
    src: url('../assets/fonts/SourceSerifPro-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Source Serif Pro Light';
    src: url('../assets/fonts/SourceSerifPro-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'DM Serif Display Normal';
    src: url('../assets/fonts/DMSerifDisplay-Regular.ttf') format('truetype');
}