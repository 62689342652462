@import "../../../styles/app.scss";

.seity-select-box-form-group {
    label {
        color: $seity-dark-green;
        @include font-base($font-source-sans, Normal, rem(18));
        padding: 0 0 0 rem(12);
    }
}

.seity-select-box-wrapper {
    position: relative;
    user-select: none;
    width: 100%;
}

.seity-select-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: start;
    width: 100%;
}

.seity-select-box-trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border: rem(1.5) solid $seity-green;
    border-radius: rem(26);
    background-color: transparent;
    cursor: pointer;
    padding: 0 rem(10) rem(5) 0;
    width: 100%;
    min-height: rem(48);
    div {
        position: relative;
        padding: rem(10) rem(20) rem(5) rem(20);
        @include font-base($font-source-sans, Normal, rem(18));
        width: 91%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $seity-dark-green;
    }
    img {
        padding: rem(7) 0 0 0;
    }
    &-error {
        border-color: $seity-orange;
    }
}

.seity-select-box-trigger-options {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border: rem(1.5) solid $seity-green;
    border-radius: rem(26) rem(26) 0 0;
    border-bottom: none;

    padding: 0 rem(10) 0 0;
    cursor: pointer;
    
    div {
        position: relative;
        padding: rem(10) rem(20) rem(5) rem(20);
        @include font-base($font-source-sans, Normal, rem(18));
        width: 91%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $seity-dark-green;
    }
    img {
        padding: rem(7) 0 0 0;
    }
}

.seity-select-box-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 1;
    background-color: $seity-cream;
    border-radius: 0 0 rem(26) rem(26);
    border: rem(1.5) solid $seity-green;
    margin-top: rem(-5);
    border-top: none;
    overflow: hidden;

    ul {
        list-style: none;
        height: auto;

        max-height: rem(200);
        overflow-y: auto;
        li {
            display: flex;
            align-items: center;
            height: rem(48);
            padding: rem(5) rem(20) rem(5) rem(20);
            width: 100%;
            @include font-base($font-source-sans, Normal, rem(18));
            color: $seity-dark-green;
            &:hover, &:active, &:focus {
                background-color: $seity-teal;
                color: $seity-cream;
                cursor: pointer;
            }
        }
    }
}
