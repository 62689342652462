@import "src/styles/app.scss";

.wbn-checkbox-container {
  display: flex;
  flex-direction: row;
  font-size: rem(16);
  align-items: flex-start;

  img {
    height: rem(24);
    width: rem(24);
    margin-right: rem(8);
  }

  .checkbox-check-icon {
    cursor: pointer;
  }

  label {
    font-family: $font-source-sans;
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(20);
    color: $seity-black;
    max-width: rem(150);
  }
}

.filter-list {
  margin-bottom: rem(24);
}

.align-item-center {
  align-items: center;
}
