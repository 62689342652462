@import "../../../styles/app.scss";
.scroll-container {
  width: rem(700);
  min-height: rem(400);
  overflow: hidden;
  &__content {
    width: rem(1400);
    display: flex;
  }
}

.viewname-container {
  .content {
    // align-items: center;
    span {
      display: inline-block;
      color: $seity-dark-green;
    }
    .text-bold {
      font-weight: 700;
    }
  }
}

a {
  cursor: pointer;
}

.savedviews-container {
  width: rem(700);
  display: inline-block;
  padding: 0 rem(20);
  span {
    @include font-base($font-source-sans, Bold);
  }
  .title {
    @include font-base($font-source-sans, Bold);
    font-size: rem(28);
    color: $seity-dark-green;
    text-align: center;
  }
  .content {
    width: 100%;
    height: rem(320);
    display: flex;
    flex-direction: column;
    margin-bottom: rem(20);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      // display: none;
    }
    .item-wrap {
      display: flex;
      margin: rem(10) rem(5);
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        width: rem(520);
        font-size: rem(17);
        line-height: rem(21);
        color: $seity-dark-green;
        padding: rem(5) 0 rem(5) rem(5);
        &.active {
          color: $seity-cream;
          background-color: $seity-green;
        }
        &__text-wrap {
          display: flex;
          flex-direction: column;
        }
        &__date {
          color: $seity-dark-green;
          margin-left: auto;
          margin-right: rem(44);
        }
      }
      .right {
        &.text {
          margin-left: rem(10);
          font-size: rem(17);
          line-height: rem(21);
          color: $seity-dark-green;    
        }
      }
      .btn-editname {
        margin-right: rem(20);
        margin-left: auto;
        color: $seity-teal;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

