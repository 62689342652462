@import "src/styles/app.scss";

.survey-textarea-container {
  display: flex;
  flex-direction: column;
  font-size: rem(16);

  label {
    @include font-base($font-source-sans, Normal);
    color: $seity-green;
    margin-bottom: rem(5);
  }

  textarea {
    @include font-base($font-source-sans, Normal);
    border: none;
    outline: none;
    resize: none;
    font-size: rem(18);
    line-height: rem(24);
    color: $seity-green;
    border-radius: rem(4);
  }
}
