@import "../../../styles/app.scss";

.surveyresults-sidemenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ul {
    width: 100%;
    overflow-y: scroll;
  }
  h1 {
    @include font-base($font-source-serif, Normal);
    font-size: rem(26);
    line-height: rem(46);
    width: 100%;
    height: rem(123);
    padding-top: rem(56);
    color: $survey-grey02100;
    text-align: center;
    border-bottom: solid rem(2) $survey-bg;
  }
  &__back {
    display: flex;
    align-items: center;
    color: $survey-grey02100;
    margin-top: rem(56) !important;
    @include font-base($font-source-sans, Normal, rem(18));
    img {
      margin-right: rem(10);
    }
  }
}
.sidemenu-item {
  background-color: $seity-cream;
  border-bottom: solid rem(2) $survey-bg;
  padding-left: rem(50);
  padding-right: rem(50);
  height: rem(100);
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
  &.active {
    background-color: $survey-bg;
  }
  &__desc {
    padding-left: rem(32);
    .desc-title {
      @include font-base($font-source-sans, Bold);
      font-size: rem(20);
      color: $survey-grey02100;
    }
    .desc-subtitle {
      @include font-base($font-source-sans, Light);
      font-size: rem(16);
      color: $survey-grey02100;
      margin-top: rem(5);
    }
    .desc-status {
      @include font-base($font-source-sans, Bold);
      font-size: rem(16);
      color: $survey-grey02100;
      margin-top: rem(5);
    }
  }
}
