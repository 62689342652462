@import "../../../styles/app.scss";

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $seity-dark-green;

    padding: rem(50) 0 rem(40) 0;
    width: 100%;
    @include respond-to(mobile) {
        padding: rem(20) 0 rem(20) 0;
    }
    h1 {
        @include font-base($font-source-serif, Normal, rem(40));
        margin: 0 0 rem(30) 0;
        @include respond-to(mobile) {
            margin: 0 0 rem(20) 0;
        }
    }
    h2 {
        @include font-base($font-source-sans, Normal, rem(24));
        margin: rem(30) 0 0 0;
    }
    h4 {
        @include font-base($font-source-sans, Bold, rem(16));
        text-decoration: underline;
        color: $seity-teal;
        cursor: pointer;
    }
    hr {
        border: rem(1) solid $seity-dark-green;
        width: 80%;
        margin: rem(15) 0 rem(20) 0;
        @include respond-to(mobile) {
            width: 75%;
        }
        @include respond-to(small-mobile) {
            width: 50%;
        }
    }
}

.profile {
    &-logout-button {
        margin: rem(25) 0 rem(30) 0;
        
        @include respond-to(mobile) {
            margin: rem(20) 0 0 0;
        }
    }
    &-bubble {
        text-align: left;

        width: 50%;
        padding: rem(10) rem(30) rem(30) rem(30);
        margin: 0 0 rem(20) 0;

        border: rem(1) solid $seity-dark-green;
        border-radius: rem(30) 0 rem(30) rem(30);
        @include respond-to(mobile) {
            width: 70%;
        }
        
        @include respond-to(small-mobile) {
            width: 50%;
        }

        label {
            margin: rem(20) rem(10) rem(5) 0;
            @include font-base($font-source-sans, Light, rem(22));
        }
        p {
            @include font-base($font-source-sans, Bold, rem(18));
            color: $seity-dark-green;
            word-wrap: break-word;
        }
        &-edit-link {
            cursor: pointer;
            float: right;
            text-decoration: underline;
            color: $seity-teal;
            @include font-base($font-source-sans, Bold, rem(16));
            display: none; //MLTODO: need to show when the api issue is resolved
        }
    }
}

