@import "../../../styles/app.scss";
.container-survey.has-sub-survey {
  margin-bottom: rem(27);
}
.survey-container-item {
  flex: 1;
  display: flex;
  min-height: rem(62);
  overflow: hidden;
  border-top-right-radius: rem(30);
  border-bottom-left-radius: rem(30);
  border: 2px solid $seity-green;
  background-color: $seity-cream01100;
  padding: rem(10) rem(20);
  margin-bottom: rem(27);
  @include respond-to(small-desktop) {
    padding: rem(5) rem(20);
    margin-bottom: rem(20);
  }
  .line-space {
    border-right: 1px solid #ccc;
  }

  .survey-item-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $seity-green;
    span {
      @include font-base($font-source-sans, Normal, rem(18));
    }
    .item-list-count {
      display: flex;
      align-items: center;
      .tree-icon {
        width: rem(20);
        margin-right: 5px;
      }
      .arrow-icon {
        width: rem(25);
      }
    }

    .survey-action {
      width: rem(25);
      margin: rem(10);
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .survey-status-Active {
      color: $seity-teal;
      text-transform: capitalize;
    }
    .survey-status-Draft {
      color: $seity-teal;
      text-transform: capitalize;
    }
    .survey-status-scheduled {
      color: $seity-orange;
      text-transform: capitalize;
    }
    .survey-status-Complete {
      color: $seity-dark-green;
      text-transform: capitalize;
    }
    .survey-question-container {
      display: flex;
      flex-direction: column;
      span {
        @include font-base($font-source-sans, Normal, rem(15));
        @include respond-to(small-desktop) {
          @include font-base($font-source-sans, Normal, rem(15));
        }
      }
      .survey-question {
        margin-bottom: rem(10);
        @include respond-to(small-desktop) {
          margin-bottom: rem(5);
        }
      }
    }
    .survey-title {
      color: $seity-green;
      @include font-base($font-source-sans, Normal, rem(18));
      @include respond-to(small-desktop) {
        @include font-base($font-source-sans, Normal, rem(15));
      }
    }
  }

  .survey-item-section.actions {
    display: flex;
    justify-content: end;
  }

  .survey-section.has-sub-survey {
    display: flex;
    justify-content: space-between;
  }
}
.survey-container-item.has-sub-survey {
  margin-bottom: rem(10);
  cursor: pointer;
}
.sub-survey-list-container {
  margin-left: rem(80);
}

.fadein,
.fadeout {
  opacity: 0;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.fadein {
  opacity: 1;
}
