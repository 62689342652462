@import "../../styles/app.scss";

$banner_height: rem(304);

.ats-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $ats-bg;
  .ats-banner {
    width: 100%;
    height: $banner_height;
    background-color: $ats-banner-bg;
    background-image: url('../../assets/ats/ats-banner-bg.png');
    padding-bottom: rem(10);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &__bg {
      position: fixed;
      top: rem(75);
      left: 0;
      width: 100%;
      height: rem(291);
    }
    &__content {
      display: flex;
      img {
        margin-top: rem(72);
        margin-left: rem(104);
      }
      .banner-title {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        h1 {
          color: $seity-cream;
          @include font-base($font-source-sans, Bold, rem(64));
        }
        p {
          color: $seity-cream;
          margin-top: rem(10);
          @include font-base($font-source-sans, Normal, rem(24));
          text-align: center;
        }
      }
    }
  }
  .ats-main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    &__sidemenu-wrap {
      display: flex;
      flex-direction: column;
      width: rem(400);
      min-height: calc(100vh - 304px - 75px);
    }
    &__sidemenu-wrap.hangon {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      margin-top: rem(75);
    }
    &__content-wrap {
      padding: rem(100) rem(100);
      flex: 1;
      display: flex;
      flex-direction: column;
      .content-wrap {
        max-width: rem(1500);
      }
    }
    &__content-wrap.hangon {
      margin-left: rem(400);
    }
  }
}
