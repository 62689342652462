@import "src/styles/app.scss";

.survey-timepicker-container {
  display: flex;
  align-items: center;
}
.survey-timepicker-container > span {
  margin: 0 rem(5);
}

.survey-timepicker-form-container {
  display: flex;
  flex-direction: column;
  margin: rem(10);
  font-size: rem(16);
  justify-content: flex-end;
  label {
    @include font-base($font-source-sans, Normal);
    color: $seity-green;
  }
}

.survey-child-timepickerform {
  margin: 0;
}

.survey-timepicker-select {
  margin: 0px;
}
