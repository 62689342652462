@import "../../../styles/app.scss";

.landing-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FBF9F7;

  .noaccess-wrap {
    margin-top: rem(100);
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: rem(150);
    }
    p {
      text-align: center;
      @include font-base($font-source-sans, Normal, rem(35));
      color: $seity-dark-green;
      margin-bottom: rem(20);
    }
  }
}

.button-disabled {
  opacity: 0.6;
  cursor: unset;
  user-select: none;
  pointer-events: none;
  display: none;
}

.welcome-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: rem(102);
  margin-bottom: rem(50);
  h1 {
    @include font-base($font-source-sans, Normal, rem(35));
    color: $seity-dark-green;
    margin-bottom: rem(20);
  }
  p {
    @include font-base($font-source-sans, Normal, rem(20));
    color: $seity-dark-green;
    margin-top: rem(35);
    text-align: center;
  }
  .button-wrap {
    display: grid;
    grid-gap: rem(24);
    grid-template-columns: repeat(1, minmax(rem(395), rem(236)));
    justify-content: center;
    @include respond-to(tablet) {
      grid-template-columns: repeat(2, minmax(rem(395), rem(236)));
    }
    @include respond-to(desktop) {
      grid-template-columns: repeat(3, minmax(rem(395), rem(236)));
    }
    button {
      height: rem(236);
      cursor: pointer;
      padding: rem(39) rem(24);
      border: rem(1) solid $survey-grey02100;
      border-radius: 0 rem(24) 0 rem(24);
      text-align: center;
      &:hover {
        opacity: 0.8;
      }
      > h3 {
        margin-top: rem(15);
        font: 600 rem(26) $font-source-sans;
        color: $survey-grey02100;
      }
    }
  }
  .company-title {
    width: 100%;
    font: 600 rem(26) $font-source-sans;
    color: $seity-dark-green;
    margin-top: rem(40);
    margin-bottom: rem(12);
    font-weight: 600;
    font-size: rem(26);
    line-height: rem(42);
  }
}
