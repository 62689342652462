@import "../../styles/app.scss";
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px rgba($seity-cream, 1) inset;
}

.login-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: clamp(51px, 8vh, 100px) 0 0 0;
  margin: 0;
  background-color: $seity-grey01100;
  background-repeat: repeat-x;
  overflow: auto;
  @include respond-to(mobile) {
    background-size: 100%;
  }
  @include respond-to(mobileLandscape) {
    padding: rem(14) rem(44) rem(23);
  }
}

.login-bubble {
  &-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: rem(375);
    height: clamp(500px, 73vh, 620px);
    text-align: center;
    padding: 0 rem(20);
    background-color: $seity-cream;
    border-radius: rem(72) rem(72) rem(72) 0;
    box-shadow: rem(4) 0 rem(4) rgba(0, 0, 0, 0.25);
    color: $seity-dark-green;
    @include respond-to(mobile) {
      width: 100%;
    }
    @include respond-to(mobileLandscape) {
      width: 100%;
      height: 100%;
      flex-direction: row;
      padding: rem(40) rem(20);
    }
    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond-to(mobileLandscape) {
        width: 50%;
        height: 100%;
        padding: 0 rem(20);
      }
      &__signup {
        display: none;
        @include respond-to(mobileLandscape) {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          span {
            color: $seity-dark-green;
            font-size: 75%;
            u {
              color: $seity-teal;
              font-weight: 600;
            }
          }
        }
      }
    }
    &__down {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 80%;
      @include respond-to(mobileLandscape) {
        width: 50%;
        padding: 0 rem(20);
      }
    }
    h1 {
      margin: clamp(20px, 4vh, 40px) 0 0 0;
      font-size: rem(36) $font-source-serif;
      @include respond-to(mobileLandscape) {
        font-size: rem(34);
        margin: rem(30) 0 0 0;
      }
    }
    h2 {
      @include font-base($font-source-sans, Normal, rem(18));
      width: rem(242);
      margin: 0 0 clamp(30px, 6vh, 60px) 0;
      @include respond-to(mobileLandscape) {
        margin: 0 auto;
      }
    }
  }
  &-logo {
    width: rem(100);
    margin: clamp(55px, 9vh, 150px) 0 0 0;
    @include respond-to(mobileLandscape) {
      width: rem(48);
      margin: 0;
    }
  }
  &-forget-password {
    margin: clamp(0px, 2vh, 13px) 0 clamp(0px, 1vh, 13px) 0;
    @include font-base($font-source-sans, Bold, rem(16));
    color: $seity-teal;
  }
  &-button {
    margin: clamp(18px, 3vh, 50px) 0 clamp(60px, 8vh, 160px) 0;
    @include respond-to(mobileLandscape) {
      margin: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 60%;
    }
  }
}

.login-signup {
  @include font-base($font-source-sans, Normal, rem(16));
  padding: rem(5) 0 rem(5) rem(40);
  color: $seity-cream;
  width: rem(414);
  @include respond-to(mobile) {
    width: 100%;
  }
  @include respond-to(mobileLandscape) {
    display: none;
  }
  u {
    @include font-base($font-source-sans, Bold, rem(16));
    cursor: pointer;
    margin: 0 0 0 rem(30);
  }
}

.login-footer {
  @include font-base($font-source-sans, Normal, rem(13));
  color: $seity-cream;
  margin: auto 0 0 0;
  padding: 0 0 rem(10) rem(16);
  width: rem(414);
  @include respond-to(mobile) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: rem(57) 0 0 0;
  }
  @include respond-to(mobileLandscape) {
    margin: rem(5) 0 0 0;
    flex-direction: row;
  }
  u {
    cursor: pointer;
    font-weight: 700;
  }
  &-copyright {
    background: url("../../assets/images/web-icons/copyright.png") no-repeat 0 rem(3) !important;
    background-size: rem(10) !important;
    padding: 0 0 0 rem(14);
    margin: 0 0 0 rem(80);
    @include respond-to(mobile) {
      margin: rem(16) 0 0 0;
    }
    @include respond-to(mobileLandscape) {
      margin: 0 0 0 auto;
    }
  }
}