@import "../../../styles/app.scss";

.wbn-grid {
  padding: rem(12) rem(0) rem(12) rem(0);
  table { // Material Table
    td.MuiTableCell-root {
      background-color: rgb(250, 248, 245);
    }
  }
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense { // Material Table Footer
    background-color: rgb(236, 236, 236);
  }
  > .MuiPaper-root {
    height: 100%;
    > .MuiTableContainer-root {
      height: 100%;
      scrollbar-color: #E4E4E4 transparent;
      scrollbar-width: thin;
      &::-webkit-scrollbar-thumb {
        background: #E4E4E4;
        border-radius: rem(3);
      }
      &::-webkit-scrollbar {
        width: rem(6);
        height: rem(9);
      }
    }
  }

}

.grid-header {
  display: flex;
  align-items: center;
  > p {
    color: $seity-black;
    font: "600" rem(14) / rem(16) $font-source-sans;
  }
  > img {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin-left: 0.5rem;
  }
  .sort-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      fill: transparent;
    }
    .black {
      fill: $seity-black;
    }
  }
  :hover {
    svg {
      fill: #aaa;
    }
  }
}

.MuiTableCell-head {
  background-color: $seity-light-grey !important;
}

.grid-cell {
  display: flex;
  align-items: center;
  > p {
    color: $seity-black;
    font: "400" rem(16) / rem(20) $font-source-sans;
  }
}

.MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root {
  background-color: rgba(0, 0, 0, .1) !important;
}
