@import '../../../styles/app.scss';

$overlayWidth: rem(500);

.reset-panel {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  z-index: 99;
}

.reset-panel__heading {
  font: 400 rem(42) $font-source-serif;
  color: $seity-light-black;
}

.reset-panel__heading--green {
  font: 400 rem(42) $font-source-serif;
  color: $seity-green;
}

.reset-panel__subheading {
  font: 600 rem(28) $font-source-serif;
  color: $seity-black;
}

.reset-panel__paragraph {
  font: 400 rem(16) $font-source-sans;
  color: $seity-black;
}

.reset-panel__paragraph--light-black {
  font: 400 rem(16) $font-source-serif;
  color: $seity-light-black;
}

.reset-panel__mt40 {
  margin-top: 40px;
}

.reset-panel__mb40 {
  margin-bottom: 40px;
}

.reset-panel__overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(#0b0e10, 0.5);
  z-index: 99;
  display: flex;
}

.reset-panel__main {
  max-height: 100vh;
  width: $overlayWidth;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: rem(20) rem(40);
  overflow-y: auto;
  position: fixed;
  min-width: 40vw;
  min-height: 100vh;
  top: 0;
  right: 0;
}

.reset-panel__back-to-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
}

.reset-panel__back-to-profile img {
  height: 20px;
  width: auto;
}

.reset-panel__back-to-profile p {
  color: $seity-green;
  font-weight: 600;
  font-size: rem(16);
}

.reset-panel__back-to-profile:hover {
  text-decoration: underline;
}

.reset-panel__close-btn {
  align-self: flex-end;
  justify-content: flex-start;
  outline: none;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.reset-panel__content {
  width: 100%;
}

.reset-panel__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
  margin: 0 auto;
}

.reset-panel__info {
  margin-top: 20px;
  margin-bottom: 20px;
}

.reset-panel__grid-title {
  opacity: .5;
  margin-bottom: 5px;
}

.reset-panel__accordion {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.reset-panel__accordion-row {
  padding-bottom: rem(30);
  padding-top: rem(30);
  border-bottom: 2px solid #ccc;
  position: relative;
  cursor: pointer;
}

.reset-panel__accordion-title {
  font-size: rem(16);
}

.reset-panel__accordion-row::after {
  content: '\276F';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.reset-panel__default {

}

.reset-panel__button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: rem(50);

  button {
    height: rem(45);
    margin-top: rem(18);
    width: rem(150);
  }
}

.reset-panel__force-password-change-disclaimer {
  margin-top: rem(40);

  .reset-panel__force-password-change-description {
    font-style: italic;
    color: #677280;
    font-size: rem(14);
    font-weight: 600;
    font-family: $font-source-sans;
  }

}

.reset-panel__sendTmpPassword, .reset-panel__manualReset, .reset-panel__autoGenerate {
  h2 {
    margin-top: rem(40);
    margin-bottom: rem(40);
  }
}

.reset-panel__custom-text-input {
  height: 56px;
  border-radius: 8px;
  border: 1px solid $survey-gray;
  background-color: $seity-white;
  padding: 16px;

  input {
    border: 1px solid $survey-gray;
  }
}

.reset-panel__error-message {
  color: $seity-orange;
}

.reset-panel__button-auto-generate {
  border: 1px solid $survey-teal-med;
}

.reset-panel__last-updated-password {
  color: #677280;
  font: 600 rem(12) $font-source-sans;
  font-style: italic;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
