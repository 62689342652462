@import "src/styles/app.scss";

.ats-outcomecategoriesaccordion {
  margin-top: rem(30);
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;

  &__header {
    padding: rem(16) rem(48);
    background: $seity-light-grey;
    cursor: pointer;
  }

  span {
    color: $survey-grey02100;
    font-size: rem(20);
  }
  img {
    margin-left: rem(-10);
  }

  &.shrink {
    max-height: rem(50);
    border-bottom: rem(1) solid $survey-grey02100;
  }
}

.ats-outcomecategoriesaccordion__expandicon{
  margin-left: rem(8) !important;
}
