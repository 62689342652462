@import "../../../styles/app.scss";

.survey-input-title {
  width: rem(422);
  height: rem(48);
  border: none;
  outline: none;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
  @include font-base($font-source-serif, Normal);
  font-size: rem(40);
  color: $survey-grey02100;
  margin-right: rem(21);
  &:focus {
    background-image: none !important;
    border: rem(1) solid $survey-grey02100;
  }
}

.survey-input-title-container{
  cursor: 'text'; 
  width: 423px; 
}