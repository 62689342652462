@import "../../../styles/app.scss";

.ats-autocomplete {
  display: flex;
  align-items: center;
  div {
    position: relative;
    width: 100%;
  }

  div input {
    margin-right: rem(16);
    flex: 1;
    background: url("../../../assets/ats/icon-search.png") center left no-repeat;
    background-position-x: rem(21);
    background-color: #fff;
    height: rem(37);
    padding-left: rem(57);
    border-radius: rem(4);
    border: 0px;
    @include font-base($font-source-sans, Normal, rem(14));
    width: 100%;
  }
}
