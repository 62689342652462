@import "../../../styles/app.scss";

.survey-report-style {
	width: 100%;
  background-color: $seity-cream;
  padding-bottom: rem(100);
  @include respond-to(desktop) {
    height: 500px;
  }
  .displayAreaViewport {
    background-color: $seity-cream;
  }
  iframe {
    border: none;
    height: calc(476vw - 571px) !important;
    @include respond-to(desktop) {
      height: 90vw !important;
    }
  }
}

.hide-report {
  visibility: hidden;
}
