@import "../../styles/app.scss";

.survey-results {
  display: flex;
  width: 100%;
  background-color: $survey-bg;
  &__content {
    flex: 1;
  }
  &-back {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: rem(20);
    cursor: pointer;
    h5 {
      @include font-base($font-source-sans, Bold, rem(20));
      color: $seity-world !important;
      text-decoration: underline;
      margin-left: rem(12);
    }
  }

  &-graphs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(20) rem(15);
    overflow-y: scroll;
  }

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: rem(56);
    padding-bottom: rem(10);
    h1 {
      @include font-base($font-source-serif, Bold, rem(30));
      color: $survey-grey02100;
      margin-bottom: rem(16);
    }
    h2 {
      @include font-base($font-source-serif, Normal, rem(28));
      color: $survey-grey02100;
    }
    span {
      @include font-base($font-source-serif, Light, rem(15));
    }
  }

  &-subheader {
    display: flex;
    flex-direction: column;
    padding: rem(10) rem(70);
    &.shadow {
      transition-duration: 0.3s;
      -webkit-box-shadow: 0 8px 6px -6px $survey-dark-gray;
      -moz-box-shadow: 0 8px 6px -6px $survey-dark-gray;
      box-shadow: 0 8px 6px -6px $survey-dark-gray;
    }
    &__wrap {
      display: flex;
      margin-top: rem(8);
    }
    h4 {
      @include font-base($font-source-serif, Bold, rem(20));
      color: $survey-grey02100;
    }
    &-count {
      display: flex;
      padding: rem(12) 0;
      margin-right: auto;
      border: rem(1) solid $survey-grey02100;
      border-radius: 0 rem(24) 0 rem(24);
      height: rem(80);
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 rem(16);
        &:first-child {
          border-right: rem(1) solid $survey-grey02100;
        }
      }
      .count-title {
        @include font-base($font-source-sans, Normal, rem(16));
        color: $survey-grey02100;
      }
      .count-number {
        @include font-base($font-source-sans, Bold, rem(18));
        color: $survey-grey02100;
      }
    }
  }
  .sidemenu-wrap {
    display: flex;
    flex-direction: column;
    width: rem(360);
    height: 100%;
    background-color: $seity-cream;
  }
}
