@import '../../styles/app.scss';

.seity-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(16);
  padding: rem(32);
  max-height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: rem(309);
  min-height: rem(200);
  max-width: 50%;
  z-index: 20;
  box-shadow: 0 2px 8px rgba(40, 41, 61, 0.08), 0 20px 32px rgba(96, 97, 112, 0.24);
  > button {
    margin-left: auto;
    background-color: unset;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  > h2 {
    color: $seity-black;
    font: 600 rem(24) $font-source-serif;
    width: 100%;
  }
  > p {
    color: $seity-black;
    font: 400 rem(16) $font-source-sans;
    width: 100%;
    margin-bottom: rem(24) !important;
  }
  &__imgCard {
    object-fit: contain;
  }
  &__btnWrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
    button {
      padding: rem(16) rem(32);
      height: rem(48);
      margin-right: rem(8);
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @include respond-to(mobile) {
    max-width: 100%;
  }
}

.seity-modal:focus {
  outline: none;
  border: none;
}

.seity-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 12;
}
