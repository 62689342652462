@import "../../styles/app.scss";

$header_height: rem(123);
$list_width: rem(1080);

.survey-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 75px);
  .sidemenu-wrap {
    display: flex;
    flex-direction: column;
    width: rem(360);
  }
  .survey-main {
    height: 100%;
    width: calc(100vw - 360px);
    min-height: calc(100vh - 4.6875rem);
    display: flex;
    justify-content: center;
    background-color: $survey-bg;
  }
  .content-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
      width: 100%;
      height: $header_height;
      border-bottom: 2px solid $survey-dark-gray;
      display: flex;
      flex-direction: column;
      // div {
      //   width: 100%;
      //   height: 100%;
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   padding: 0 rem(72);
      //   margin: 0 auto;
      h1 {
        padding: rem(56) 0;
        @include font-base($font-source-serif, Normal);
        color: $survey-grey02100;
        font-size: rem(40);
        line-height: rem(50);
        text-align: center;
      }
      // }
      .survey-tabmenu-wrap {
        width: rem(306);
        height: rem(36);
        margin-top: rem(88);
        margin-left: rem(72);
      }
    }
    &__menu {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $survey-bg;
      padding: 0 rem(72);
      @include respond-to(small-desktop) {
        padding: rem(16) rem(72) rem(24) rem(72);
      }
      &.shadow {
        transition-duration: 0.3s;
        -webkit-box-shadow: 0 8px 6px -6px $survey-dark-gray;
        -moz-box-shadow: 0 8px 6px -6px $survey-dark-gray;
        box-shadow: 0 8px 6px -6px $survey-dark-gray;
      }
      > span {
        color: $survey-grey02100;
        @include font-base($font-source-sans, Light);
        font-size: rem(16);
        line-height: rem(20);
        height: 3rem;
      }
      .sortby-wrap {
        margin-left: auto;
        display: flex;
        height: rem(40);
        align-items: center;
        > span {
          margin-right: rem(32);
          @include font-base($font-source-sans, Bold);
          color: $survey-grey02100;
          font-size: rem(20);
          line-height: rem(22);
        }
        a {
          background-color: $seity-cream;
          height: rem(32);
        }
      }
      z-index: 1;
    }
    &__list {
      width: 100%;
      padding: 0 rem(72);
      padding: rem(10) rem(72);
      background-color: $survey-bg;
      overflow-y: scroll;
    }
  }
}

.list-content {
  .survey-item-conteiner-wrap {
    position: relative;
  }
  max-width: rem(1080);
  margin: 0 auto;
}

.survey-tabmenu {
  display: flex;
  flex-direction: row;
  background-color: $survey-greyc5c3bc;
  border-radius: rem(10) rem(10) 0 0;
  width: 100%;
  height: 100%;
  button {
    flex: 1;
    border: 0;
    background-color: $survey-greyc5c3bc;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    span {
      @include font-base($font-source-sans, Light);
      font-size: rem(16);
      color: $survey-grey02100;
      margin-left: rem(18);
    }
    img {
      margin-left: rem(30);
    }
    &:first-child {
      border-top-left-radius: rem(10);
      border-bottom-right-radius: rem(10);
    }
    &:last-child {
      border-top-right-radius: rem(10);
      border-bottom-left-radius: rem(10);
    }
    &.active {
      background-color: $seity-cream;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

.sortby-selector {
  width: rem(170);
  height: rem(40);
  background-color: $seity-cream;
  border-radius: rem(10);
  border: solid rem(2) $survey-grey02100;

  @include font-base($font-source-sans, Light);
  font-size: rem(18);
  color: $survey-grey02100;
}

.survey-created-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #314947;
  h1 {
    @include font-base($font-source-dm-seif, Normal, rem(40));
    margin-bottom: rem(40);
  }
  h4 {
    @include font-base($font-source-sans, Normal, rem(16) / rem(25));
    margin-top: rem(40);
    margin-bottom: rem(20);
  }

  &-button {
    min-width: rem(368);
  }
}

.survey-saved-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #314947;
  h1 {
    @include font-base($font-source-dm-seif, Normal, rem(40));
  }
  h4 {
    @include font-base($font-source-sans, Normal, rem(16) / rem(25));
    max-width: rem(610);
    b {
      @include font-base($font-source-sans, Bold);
    }
  }
}

.survey-scheduled-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #314947;
  h1 {
    @include font-base($font-source-dm-seif, Normal, rem(40));
  }
  h4 {
    @include font-base($font-source-sans, Normal, rem(16) / rem(25));
    max-width: rem(610);
    b {
      @include font-base($font-source-sans, Bold, rem(16) / rem(25));
    }
  }
}

.survey-active-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #314947;
  h1 {
    @include font-base($font-source-dm-seif, Normal, rem(40));
    margin-bottom: rem(10);
  }
  h4 {
    @include font-base($font-source-sans, Normal, rem(16)/rem(25));
    max-width: rem(610);
    margin-top: rem(10);
    b {
      @include font-base($font-source-sans, Bold, rem(16) / rem(25));
    }
  }
}
