@import "../../../styles/app.scss";

.ats-sidemenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-right: solid rem(2) $survey-bg;
  padding-top: rem(80);
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      a {
        display: flex;
        @include font-base($font-source-sans, Light, rem(24));
        color: #313131;
      }
    }
  }
  &__sidemenu-item {
    height: rem(60);
    padding-left: rem(104);
    align-items: center;
    &:hover {
      opacity: 0.5;
    }
    &.active {
      color: $seity-dark-green;
      @include font-base($font-source-sans, Bold, rem(24));
    }
  }
  &__sidemenu-item.submenu-item {
    margin-left: rem(20);
    height: rem(45);
    color: $seity-dark-green;
    &:hover {
      opacity: 0.5;
    }
    &.active {
      color: $seity-dark-green;
    }
  }
  &__submenu {
    opacity: 0;
    display: none !important;
  }
  &__submenu.show {
    display: flex !important;
    opacity: 1;
    transition: ease 1s;
  }
}
