@import "src/styles/app.scss";

.survey-radio-button-item-wrapper {
  display: flex;
  font-size: rem(16);
  cursor: pointer;
  align-items: center;
  span {
    @include font-base($font-source-sans, Normal);
    color: $seity-green;
  }

  div.survey-radio-button {
    margin: 0 rem(12) 0 0;
    font: inherit;
    color: black;
    width: rem(15);
    height: rem(15);
    border: rem(2) solid black;
    border-radius: 50%;
    padding: rem(1);
  }

  .survey-radio-button.checked div.survey-radio-button-fill {
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 50%;
  }
}
.survey-radio-button-group-wrapper {
  margin: rem(10);
}
