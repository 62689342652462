@import './partials/fc';

// header height (mobile)
$mobile-header-height: 64px !default;
// rem for header height (mobile)
$rem-mobile-header-height: rem($mobile-header-height);

// header height (desktop)
$desktop-header-height: 75px !default;
// rem for header height (desktop)
$rem-desktop-header-height: rem($desktop-header-height);

// main container padding
$rem-mobile-padding: rem(10);
$rem-tablet-padding: rem(16);
$rem-desktop-padding: rem(24);

// Font variables
$font-source-sans: 'Source Sans Pro Normal';
$font-source-sans-bold: 'Source Sans Pro Bold';
$font-source-serif: 'Source Serif Pro Normal';
$font-source-dm-seif: 'DM Serif Display Normal';
$font-serif-display: 'DM Serif Display';
$font-source-sans-pro-regular: 'Source Sans Pro-Regular';
$font-source-sans-semi-bold: 'Source Sans Pro SemiBold';
$font-source-serif-bold: 'Source Serif Pro Bold';
$font-source-serif-semi-bold: 'Source Serif Pro SemiBold';
