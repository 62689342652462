@import '../../styles/app.scss';

.seity-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $seity-teal;
  color: $seity-white;
  padding: 0 rem(33);
  text-align: center;
  text-decoration: none;
  font-size: rem(20);
  font-family: $font-source-sans;
  font-weight: bold;
  cursor: pointer;
  transition-duration: 0.4s;
  border: rem(2) solid $seity-teal;
  border-radius: rem(8) !important;
  line-height: rem(28);
  height: rem(60);
  &__submit {
    @extend .seity-button;
    background-color: $seity-middle-teal;
  }
  &__red {
    @extend .seity-button;
    background: none;
    border: none;
    color: #dc2f02;
    outline: none;
  }
  &__cancel {
    @extend .seity-button;
    background-color: transparent;
    color: $seity-teal;
  }
  &__dark {
    @extend .seity-button;
    background-color: $seity-green;
    color: $seity-cream;
    border: rem(2) solid $seity-cream;
  }
  &__gray {
    @extend .seity-button;
    background-color: $seity-light-grey;
    color: $seity-dark-green;
    border: rem(2) solid $seity-cream;
  }
  &__link {
    @extend.seity-button;
    background: none;
    color: $seity-teal;
    border: none;
    text-decoration: underline;
    font-family: $font-source-sans-semi-bold;
    padding: rem(5) rem(10);
  }
  &__discard {
    @extend.seity-button;
    background: none;
    color: $seity-black;
    border: none;
    font-family: $font-source-sans-bold;
    padding: rem(5) rem(10);
  }
  &__black {
    @extend.seity-button;
    background: none;
    color: $seity-black;
    border: rem(2) solid $seity-black;
    font-family: $font-source-sans-bold;
  }
  &__plain-text {
    @extend.seity-button;
    background: none;
    color: black;
    border: none;
  }
  > img {
    height: calc(100% - 8px);
    margin-right: rem(10);
    object-fit: none;
  }
}

.btn-block {
  background-color: #fff8eb;
  border: solid 2px #56a3a6;
  color: #56a3a6;
}

.seity-button:disabled {
  color: #56a3a6;
  background: #bbdadb;
  border: none;
  cursor: default;
}

.btn-primary {
  box-shadow: none;
}