@import "../../../styles/app.scss";

.ats-corevalues {
  display: flex;
  flex-direction: column;
  .ats-corevalues-text {
    color: $survey-grey02100;
    line-height: rem(32);
    margin-bottom: rem(50);
    @include font-base($font-source-sans, Normal, rem(24));
  }
  &__searchbar {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: rem(45);
    div {
      position: relative;
      width: 100%;
    }
    .searchfield,
    div input {
      margin-right: rem(16);
      flex: 1;
      background: url("../../../assets/ats/icon-search.png") center left
        no-repeat;
      background-position-x: rem(21);
      background-color: #fff;
      height: rem(37);
      padding-left: rem(57);
      border-radius: rem(4);
      border: 0px;
      @include font-base($font-source-sans, Normal, rem(14));
      width: 100%;
    }
    .sortby-selector {
      margin-right: rem(16);
      width: rem(170);
      height: rem(40);
      background-color: $ats-bg;
      border-radius: rem(4);
      border: solid rem(1) $survey-grey02100;

      @include font-base($font-source-sans, Light);
      font-size: rem(18);
      color: $survey-grey02100;
    }
    button {
      margin-top: rem(45);
      margin-left: auto;
    }
  }
}
