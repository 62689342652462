@import "../../../../styles/app.scss";

.survey-modal-scheduler-title {
  @include font-base($font-source-sans, Normal);
  font-size: rem(40);
  line-height: rem(40);
  color: $seity-dark-green;
  margin-bottom: rem(32);
}

.survey-scheduler-wrapper {
  width: 80%;
  margin-top: rem(40);
  display: flex;
  flex-direction: column;
  height: 100%;
  .question-content {
    padding: rem(40) 0 rem(24);
    flex: 1;
    overflow-y: none !important;
    // -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    list-style: none;
    li {
      margin-bottom: rem(32);
      &:last-child {
        margin-bottom: rem(24);
      }
    }
  }
  .modal-footer-container {
    margin-top: 30px;
    p {
      @include font-base($font-source-sans, Normal);
      margin-bottom: rem(24);
      font-size: rem(16);
      color: $seity-green;
      line-height: rem(20);
    }
  }

  .survey-input-inline {
    display: flex;
    align-items: flex-end;

    .input-days {
      display: flex;
      align-items: center;
      margin-right: rem(20);
      span {
        margin-left: rem(10);
      }
    }
    .select-repeat {
      width: rem(242);
    }

    .every-input-form {
      display: flex;
      align-items: center;

      span {
        color: $seity-green;
        margin-right: rem(23);
        margin-left: rem(25);
      }

      .survey-frequency {
        margin-left: rem(10);
      }
      .select-every {
        width: rem(68);
      }
    }
  }
  .survey-input-class-base {
    margin: 0 rem(16) 0 0 !important;
  }
  .select-day-of-week {
    width: 36.5%;
  }
  .input-spacing {
    margin-top: rem(23);
  }

  .survey-checkbox-group {
    span {
      @include font-base($font-source-sans, Normal);
      color: $seity-green;
      margin-bottom: rem(5);
      width: 100%;
    }
  }

  .question-footer {
    display: flex;
    padding: rem(24) rem(104);
    width: 100%;
    height: rem(98);
    border-top: 2px solid rgba(0, 0, 0, 0.35);
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $seity-cream;
    .back-button {
      margin-right: auto;
    }
  }
}
