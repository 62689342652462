@import "../../../styles/app.scss";

.ats-typography-item {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(40);

  &__headline {
    @include font-base($font-source-serif, Normal, rem(36));
    font-weight: 400;
    color: $seity-green;
  }
  &__family-name {
    @include font-base($font-source-sans, Normal, rem(24));
    font-weight: 600;
    color: $seity-green;
    margin-top: rem(4);
  }
  &__font-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: rem(38);
  }

  &__font-container {
    border: 2px solid $seity-green;
    display: flex;
    justify-content: space-between;
    padding: rem(40) rem(20);
    margin-top: rem(16);
  }
  &__font {
    @include font-base($font-source-sans, Normal, rem(24));
    color: $seity-green;
  }

  &__font-example {
    @include font-base($font-source-dm-seif, Normal, rem(36));
    font-weight: 400;
    color: $survey-grey02100;
  }

  &__font-list {
    @include font-base($font-source-dm-seif, Normal, rem(36));
    font-weight: 400;
    color: $survey-grey02100;
  }

  &__font-size-container {
    color: $survey-grey02100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem(0) rem(20);

    span.size-text {
      @include font-base($font-source-sans, Normal, rem(24));
      font-weight: 600;
    }
    span.size {
      @include font-base($font-source-sans, Normal, rem(30));
      font-weight: 600;
    }
  }
  .ats-typography-item__font-example.sourceSerif,
  .ats-typography-item__font-list.sourceSerif {
    @include font-base($font-source-serif, Normal, rem(30));
    font-weight: 400;
  }

  .ats-typography-item__font-example.sourceSans,
  .ats-typography-item__font-list.sourceSans {
    @include font-base($font-source-sans, Normal, rem(30));
    font-weight: 400;
    font-size: rem(18);
  }
}
