@import "src/styles/app.scss";

.survey-chart {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: rem(1) solid $survey-grey02100;
  border-radius: 0 rem(24) 0 rem(24);
  background-color: $seity-cream;
  margin: rem(15) 0;
  max-width: rem(1152);
  &__header {
    display: flex;
    margin-bottom: rem(20);
    h4 {
      margin: rem(15) auto 0 rem(15) !important;
      color: $survey-grey02100;
      @include font-base($font-source-sans, Bold, rem(27));
    }
    span {
      padding: rem(15) rem(20);
      text-align: center;
      color: $survey-grey02100;
      background-color: $seity-spirit;
      @include font-base($font-source-sans, Bold, rem(23));
      border-left: rem(1) solid $survey-grey02100;
      border-bottom: rem(1) solid $survey-grey02100;
      border-bottom-left-radius: rem(24);
      border-top-right-radius: rem(24);
    }
  }

  .survey-child-select {
    margin: 0 !important;
  }
}

.survey-chart-item {
  display: flex;
  padding: 0 rem(15);
  margin-bottom: rem(16);
  &__line {
    flex: 1;
    height: rem(22);
    background-color: #BEBFBA;
    margin-right: rem(15);
    div {
      height: 100%;
    }
  }
  &__count {
    color: $survey-grey02100;
    @include font-base($font-source-sans, Bold, rem(16));
  }
  &__answer {
    width: 18%;
    text-align: right;
    color: $survey-grey02100;
    @include font-base($font-source-sans, Normal, rem(16));
  }
}
