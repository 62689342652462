@import '../../../styles/app.scss';

.wbn-filterWrap::-webkit-scrollbar-track {
  background: transparent;
}

.wbn-filterWrap::-webkit-scrollbar-thumb {
  background: #E4E4E4;
  border-radius: rem(1);
}

.wbn-filterWrap::-webkit-scrollbar {
  width: rem(4);
}

.wbn-filterWrap {
  border-bottom: rem(1) solid #E4E4E4;
  padding: rem(24) rem(40) rem(24) rem(40);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-color: #E4E4E4 transparent;
  scrollbar-width: thin;

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
    > h5 {
      font: 400 rem(18) $font-source-sans;
      color: $seity-light-black;
      margin-right: auto;
    }
    > img {
      width: rem(13.33);
      height: rem(6.67);
      transition-duration: 0.5s;
      &.open {
        transform: rotate(180deg);
      } 
    }
  }
  &__body {
    transition-duration: 0.5s;
    &.open {
      max-height: rem(500);
    }
    &.close {
      overflow: hidden;
      max-height: 0;
    }
    .wbn-checkbox-container {
      margin-bottom: rem(16);
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: rem(16);
      }
    }
  }
}
