@import "../../../../styles/app.scss";

.wbn-tooltip-title {
  font: 600 rem(18) $font-source-sans;
  color: $seity-light-black;
  text-align: center;
  margin-bottom: rem(32) !important;
}

.wbn-component-tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(16);
  justify-content: flex-start;

  p {
    margin: 0;
    font: 400 rem(16) $font-source-sans;
    color: $seity-black;
  }
}

