@import "../../styles/app.scss";

.survey-create {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: rem(40) rem(104) rem(122);
  .stepper-container {
    display: flex;
    align-items: center;
    span {
      width: rem(32);
      height: rem(32);
      text-align: center;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      border-radius: rem(16);
      background-color: $survey-teal-med;
      color: white;
      @include font-base($font-source-sans, Normal);
      margin-right: rem(16);
    }
    span.completed {
      background-color: $survey-teal-dark;
    }
    span.unfocused {
      background-color: $survey-gray;
    }
    p {
      color: $survey-black;
      @include font-base($font-source-sans, Normal);
      font-size: rem(16);
    }
    p.unfocused {
      color: $survey-gray;
    }
    div {
      background-color: $survey-gray;
      width: rem(25);
      height: rem(1);
      margin: 0 rem(16);
    }
  }
}

.survey-audience-panel {
  border: rem(1) solid $survey-gray;
  background-color: white;
  border-radius: rem(8);
}
