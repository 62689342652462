@import "../../../styles/app.scss";

.editlocation-container {
  width: rem(444);
  height: 100%;
  margin: rem(20);
  span {
    @include font-base($font-source-sans, Bold);
  }
  .title {
    @include font-base($font-source-sans, Bold);
    font-size: rem(28);
    color: $seity-dark-green;
    text-align: center;
  }
  .content {
    background-color: #dedbd3;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: rem(27) rem(20);
    margin-bottom: rem(20);
    border: solid #ceccc6 rem(1);
    border-top-left-radius: rem(40);
    border-bottom-right-radius: rem(40);
    .border-bottom {
      height: rem(1);
      background-color: #ceccc6;
      margin: rem(20) 0;
    }
    .item-wrap {
      display: flex;
      margin: rem(10) rem(5);
      align-items: center;
      width: 100%;
      .left {
        width: 25%;
        font-size: rem(17);
        line-height: rem(21);
        color: $seity-dark-green;
      }
      .right {
        width: 75%;
        &.text {
          margin-left: rem(10);
          font-size: rem(17);
          line-height: rem(21);
          color: $seity-dark-green;    
        }
      }
    }
  }
  .button-category {
    padding: rem(7) rem(20);
    color: $seity-dark-green;
    font-size: rem(17);
    @include font-base($font-source-sans, Normal);
  }
  .button-category.active {
    color: $seity-cream;
    background-color: $seity-dark-green;
    border-radius: rem(25);
  }
}
.selectbox-content {
  margin-bottom: rem(40);
  width: 100%;
  padding: 0 10px;
  ul {
    background-color: #dedbd3;
    list-style: none;
    height: auto;
    max-height: rem(400);
    overflow-y: scroll;
    li {
      display: flex;
      align-items: center;
      height: rem(48);
      padding: rem(5) rem(20) rem(5) rem(20);
      width: 100%;
      @include font-base($font-source-serif, Normal, rem(18));
      color: $seity-dark-green;
      &:hover, &:active, &:focus {
        background-color: $seity-mind;
        color: $seity-cream;
        cursor: pointer;
      }
    }
    li.active {
      background-color: $seity-teal;
      color: $seity-cream;
    }
  }
}
.selectbox-title {
  @include font-base($font-source-serif, Normal, rem(25));
  color: $seity-dark-green;
  margin-bottom: rem(15);
}
.filter-button-wrap {
  width: 75%;
  display: flex;
  padding: rem(10) rem(20);
  border: rem(1) solid $seity-dark-green;
  border-radius: rem(30);
  color: $seity-dark-green;
  &__title {
    display: inline-block;
    font-size: rem(17);
    line-height: rem(21);
    color: $seity-dark-green;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: rem(10);
  }
  img {
    margin-left: auto;
  }
}
