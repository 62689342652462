@import "../../../styles/app.scss";

.wbn-disabled-overlay {
  background-color: rgba(0, 0, 0, 0);
}

.wbn-savedViews-modal {
  margin-top: rem(12);
  min-width: rem(294);
  min-height: rem(200);
  padding: rem(32) rem(16);
  transform: none;
  > .wbn-modal-children-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    > h3 {
      color: $seity-light-black;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
    }
    > .wbn-modal-btn-wrap {
      display: flex;
      flex-direction: row;
      margin-top: auto;
      > .wbn-modal-button {
        height: 52px;
        border-radius: 8px;
        transition: opacity 0.3s ease;
      }
      > button {
        &:first-child {
          padding: 0 rem(32);
        }
        &:last-child {
          padding: 0px;
          width: rem(147);
        }
      }
    }
    > .wbn-modal-content-wrap {
      margin: rem(24) 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: rem(16);
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      > div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        border: rem(1) solid $survey-gray;
        border-radius: rem(8);
        padding: rem(16);
        &.sel {
          border-color: $survey-teal-dark;
        }
        > button {
          padding: 0;
          border: none;
          background-color: unset;
          cursor: pointer;
          &:first-child {
            display: flex;
            flex: 1;
            > img {
              margin-right: rem(8);
            }
            > div {
              display: flex;
              flex-direction: column;
              p {
                font: 600 rem(16) $font-source-sans;
                color: black;
                &:last-child {
                  color: #677280;
                }
              }
            }
          }
          &:last-child {
            margin-left: rem(8);
          }
        }
      }
    }
  }
}
