@import "../../styles/app.scss";

.home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 rem(10);
  padding: rem(20) 0;

  .error_log {
    position: fixed;
    text-align: center;
    top: 50%;
    width: 100%;
    color: $seity-dark-green;
  }

  .analytics-view-wrap {
    width: rem(250);
    margin-left: rem(20);
    &.disabled {
      user-select: none;
      pointer-events: none;
      opacity: 0.8;
    }
  }
}

.landing-title {
  @include font-base($font-source-serif, Bold);
  font-size: rem(30);
  line-height: rem(35);
  color: $seity-dark-green;
  text-align: center;
  padding-top: rem(30);
  height: 15%;
}

.editloc-wrap {
  border: rem(2) solid $seity-dark-green;
  border-radius: rem(24);
  margin: rem(50) auto;
  height: 85%;
}

.home-header {
  height: rem(45);
  margin: rem(20) rem(10);
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-family: $font-source-sans;
    font-size: rem(14);
    color: $seity-green;
  }
  a {
    @include font-base($font-source-sans, Bold);
    font-size: rem(20);
    color: $seity-cream;
    padding: rem(13) rem(33);
    background-color: $seity-teal;
    border-radius: rem(24);
    text-decoration: none;
  }
}
