@import "../../../styles/app.scss";

.wbn-checklist {
  width: 100%;
  margin-top: rem(24);

  .show-all {
    margin-top: rem(24);
  }
}

.text {
  color: $survey-teal-dark;
  font: "400" rem(16) / rem(20) $font-source-sans;
}

.bold-text {
  font-weight: 600;
}

.bottom-margin {
  margin-bottom: rem(24);
}
