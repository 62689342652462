@import "../../../styles/app.scss";

.ats-outcomecategoriesitem {
  display: flex;
  flex-direction: column;
  margin-top: rem(30);
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  &.shrink {
    max-height: rem(70);
    border-bottom: rem(1) solid $survey-grey02100;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: rem(25);
  }
  h2 {
    margin-top: rem(10) !important;
    color: $seity-dark-green;
    margin-left: rem(10);
    @include font-base($font-source-serif, Bold, rem(36));
  }
  h5 {
    @include font-base($font-source-sans, Bold, rem(24));
    color: $survey-grey02100;
    margin-bottom: rem(16);
  }

  &__content-wrap {
    border: rem(1) solid $survey-grey02100;
    border-radius: rem(5);

    &__container {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 18px;
      margin: rem(16) 0;
      padding: rem(36) rem(39);
    }
  }
  &__caption {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(40);
    .btn-copy {
      margin-left: auto;
      margin-top: -5px;
    }
    &__description {
      @include font-base($font-source-sans, Normal, rem(24));
      color: $survey-grey02100;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(34);
    }
  }
  .outcomecategory-icon {
    display: flex;
    margin-bottom: rem(29);
    align-items: flex-start;
    img {
      margin-right: auto;
    }
  }
}
