// Set up  
@import './variables';
@import './fonts';
@import './colors';
@import './partials/normalize';

// Basic
@import './partials/layout';
@import './partials/common';
@import './partials/spacing';
@import './partials/elements';

.seity-main {
  @include main-margin-top;
  @include main-width;
  @include main-min-height;
}
