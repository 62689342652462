@import '../../styles/app.scss';

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip {
  position: absolute;
  border-radius: rem(10);
  left: 50%;
  transform: translateX(-50%);
  color: $seity-white;
  overflow: hidden;
  background: $seity-grey011000dark;
  font: rem(16) $font-source-sans;
  z-index: 100;
  white-space: nowrap;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);}

// tool tip positioning
.tooltip.top {
  top: calc(rem(65) * -1);
}

.tooltip.topRight {
  top: calc(rem(30) * -1);
  left: 100%;
  transform: translateX(0) translateY(-50%);
}

.tooltip.right {
  left: calc(100% + rem(10));
  top: 40%;
  transform: translateX(0) translateY(-50%);
}

.tooltip.bottomRight {
  left: calc(100% + rem(10));
  right: auto;
  transform: translateX(0) translateY(10%);
  top: rem(50);
}

.tooltip.bottom {
  top: rem(40);
}

.tooltip.bottomLeft {
  left: auto;
  right: calc(100% + rem(10));
  transform: translateX(0) translateY(-50%);
  top: rem(50);
}

.tooltip.left {
  left: auto;
  right: calc(100% + rem(20));
  top: 40%;
  transform: translateX(0) translateY(-50%);
}
.tooltip.topLeft {
  top: calc(rem(30) * -1);
  left: auto;
  right: calc(100% + rem(10));
  transform: translateX(0) translateY(-50%);
}
