// 1. Set default font family to sans-serif.
// 2. Prevent iOS text size adjust after orientation change, without disabling user zoom.
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

// Remove default margin.
body {
  margin: 0;
  background-color: $seity-cream;
}

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}