@import "../../../../styles/app.scss";

.sub-survey-container-item {
  flex: 1;
  display: flex;
  margin: 0 rem(20);
  min-height: rem(62);
  overflow: hidden;
  border-top-right-radius: rem(30);
  border-bottom-left-radius: rem(30);
  border: 2px solid $seity-green;
  background-color: $seity-grey02100;
  padding: rem(5) rem(20);
  margin-bottom: rem(10);

  .line-space {
    border-right: 1px solid $seity-dark-green;
  }

  .survey-item-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $seity-green;

    .survey-title {
      color: $seity-green;
      font-weight: bold;
      font-size: rem(18);
    }

    .survey-action {
      width: rem(100);
      margin: 0 rem(10);
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .survey-status-active {
      color: $seity-teal;
      text-transform: capitalize;
    }
    .survey-status-scheduled {
      color: $seity-orange;
      text-transform: capitalize;
    }
    .survey-status-completed {
      color: $seity-dark-green;
      text-transform: capitalize;
    }
    .survey-question-container {
      display: flex;
      flex-direction: column;

      .survey-question {
        margin-bottom: rem(5);
      }
    }
  }

  .survey-item-section.actions {
    display: flex;
    justify-content: end;
    flex: 0.7;
  }
}
