.mt30 {
  margin-top: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt20 {
  margin-top: 20px;
}

.plr20 {
  padding: 0px 20px;
}

.plr40 {
  padding: 0px 40px;
}