@import "../../../styles/app.scss";

.maintenance {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: rem(40) 0 rem(40) 0;
    width: 100%;
    color: $seity-dark-green;
    
    @include respond-to(mobile) {
      margin: rem(20) 0 rem(20) 0;
    }
    .maintenance-content-wrap {
      width: rem(300);
    }
    h1 {
      @include font-base($font-source-serif, Normal, rem(40));
      margin: 0 0 rem(40) 0;

      @include respond-to(mobile) {
        @include font-base($font-source-serif, Normal, rem(35));
        margin: 0 0 rem(20) 0;
      }
    }
    h4 {
      @include font-base($font-source-sans, Normal, rem(16));
      margin: 0 0 rem(40) 0; 

      @include respond-to(mobile) {
        margin: 0 0 rem(20) 0;
      }
    }
  }
  &-api-response {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(40);
    padding: rem(20) 0 0 0;
    color: $seity-orange;
    @include font-base($font-source-sans, Normal, rem(14));
    font-style: italic;
  }
  &-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h2 {
      @include font-base($font-source-sans, Normal, rem(18));
      color: $seity-green;
      width: rem(225);
      margin: 0 0 rem(20) 0;
    }
  }
}

.form-container-upper {
  display: flex;
  margin: 0 0 rem(10) 0;
}

.form-container-lower {
  display: flex;
  margin: rem(10) 0 0 0;
}

.cp-modal {
  h2 {
    text-align: center;
    width: rem(200);
    @include font-base($font-source-sans, Normal, rem(18));
    margin: 0 0 rem(20) 0;
  }
}
