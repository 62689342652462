@import "~react-datepicker/dist/react-datepicker.css";
@import "src/styles/app.scss";

.survey-datepicker-form-container {
  display: flex;
  flex-direction: column;
  margin: rem(10);
  font-size: rem(16);
  width: rem(242);
  position: relative;

  label {
    @include font-base($font-source-sans, Normal);
    color: $seity-green;
    margin-bottom: rem(5);
  }
}

.survey-datepicker-container {
  display: flex;
  flex-direction: column;
  margin: rem(10);
  font-size: rem(16);
  max-width: rem(242);
  position: relative;
  .survey-datepicker-header {
    display: flex;
    justify-content: space-between;
    @include font-base($font-source-sans, Normal);
    border-bottom: rem(1.5) solid $seity-dark-green;
    padding-bottom: rem(8);
  }

  label {
    @include font-base($font-source-sans, Normal);
    color: $seity-green;
    margin-bottom: rem(5);
  }

  input {
    @include font-base($font-source-sans, Normal);
    border: 1px solid $seity-grey;
    outline: none;
    font-size: rem(16);
    height: rem(48);
    padding-left: rem(10);
    min-width: rem(100);
    width: 100%;
  }

  .survey-datepicker-input-icon {
    width: rem(25);
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
  }
}

.survey-datepicker-header {
  margin: 0 rem(5);
  .survey-date-item-container {
    span {
      @include font-base($font-source-sans, Normal);
      cursor: pointer;
      font-size: rem(16);
      user-select: none;
      color: $seity-green;
    }
    .current-date-value {
      margin: 0 rem(5);
    }
  }
}

.react-datepicker__header.react-datepicker__header--custom {
  background: $seity-white !important;
}

.react-datepicker__header {
  border: none !important;
}

.react-datepicker__day-names {
  margin-bottom: -17px !important;
}

.react-datepicker__day-name,
.react-datepicker__week div {
  @include font-base($font-source-sans, Normal);
  color: $seity-dark-green;
  font-size: rem(14);
}
.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  @include font-base($font-source-sans, Normal);
  background-color: $seity-mind;
  color: $seity-dark-green;
  font-size: rem(14);
}

.react-datepicker__month-container {
  float: left;
  border: rem(1) solid $seity-green !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  display: none;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding: 0px;
}
.survey-child-timepickerform {
  margin: 0;
}
