@import '../../../styles/app.scss';

.wbn-districtFilter {
  padding: rem(24) 0 0 0;
  overflow: hidden;
  // overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    > button {
      margin-left: auto;
      width: rem(70);
      height: rem(23);
      border: none;
      background-color: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      > img {
        margin-left: auto;
        width: rem(8.89);
        height: rem(4.44);
        transition-duration: 0.5s;
        &.open {
          transform: rotate(180deg);
        } 
      }
    }
  }
  &__body {
    transition-duration: 0.5s;
    margin: 0 0 0 rem(30);
    &.open {
      max-height: rem(1000);
    }
    &.close {
      overflow: hidden;
      max-height: 0;
    }
    > div {
      &:first-child {
        margin-top: rem(16);
      }
    }
    .wbn-checkbox-container {
      margin-bottom: rem(16);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
