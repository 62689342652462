@import "../../../styles/app.scss";

.wbn-tooltip-holder {
  position: relative;
}

.wbn-tooltip-basic {
  background-color: $wbn-dark-charcoal;
  opacity: 1;
  padding: rem(16);
  position: absolute !important;
  max-width: rem(300);
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  > p {
    margin: 0px;
    font: 600 rem(16) $font-source-sans;
    color: $wbn-whitesmoke;
    text-align: left;
  }
  z-index: 3 !important;
}

.wbn-tooltip-component {
  background: $seity-white !important;
  opacity: 1 !important;
  padding: rem(32) !important;
  position: absolute !important;
  border-radius: rem(16) !important;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24) !important;
  z-index: 3 !important;
}
