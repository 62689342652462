@import "../../../styles/app.scss";

.ats-brand-typography {
  display: flex;
  flex-direction: column;

  &__item-wrapper {
    @include font-base($font-source-sans, Normal, rem(28));
    color: $survey-grey02100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(32);

    .selected {
      @include font-base($font-source-sans, Bold, rem(36));
      color: $seity-green;
    }

    span {
      cursor: pointer;
      margin: rem(0) rem(15);
    }
  }
  .ats-brand-typography-text {
    color: $ats-brand-text;
    line-height: rem(32);
    margin-bottom: rem(50);
    @include font-base($font-source-sans, Light, rem(24));
  }
}
