@import '../../../styles/app.scss';

.wbn-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(8) rem(24);
  background-color: white;
  border: 1px solid white;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.05);
  color: black;
  font: 600 rem(16) $font-source-sans;
  line-height: rem(20);
  cursor: pointer;
  transition-duration: 0.4s;
  border: none;
  border-radius: rem(8);
  height: rem(40);
  &__icon {
    height: calc(100% - 8px);
    margin-right: rem(10);
    object-fit: none;
  }
  &__caret {
    transition-duration: 0.5s;
    margin-left: auto;
  }
  &.selected {
    .wbn-button__caret {
      transform: rotate(180deg);
    }
    background-color: $seity-spirit;
    border: 1px solid $survey-teal-med;
  }
  &.plain-text {
    background-color: transparent;
    border: none;
    color: $survey-teal-dark;
    box-shadow: none;
  }
  &.submit {
    background-color: $seity-teal;
    color: $seity-white;
  }
}

.wbn-button:disabled {
  color: #677280;
  background: #E4E4E4;
  border: none;
  cursor: default;
  img {
    opacity: 0.7;
  }
}
