@import "src/styles/app.scss";

.survey-text-input-container {
  display: flex;
  flex-direction: column;
  margin: rem(10);
  font-size: rem(16);

  label {
    @include font-base($font-source-sans, Normal);
    color: $seity-green;
    margin-bottom: rem(5);
  }

  input {
    @include font-base($font-source-sans, Normal);
    border: none;
    outline: none;
    font-size: rem(16);
    height: rem(38);
    padding-left: rem(10);
  }
}
