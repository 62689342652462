@import "../../styles/app.scss";

.loader-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.loader-mask {
  background-color: gray;
  position: absolute;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}
.loader-content {
  margin-top: calc(100vh / 3);
  width: rem(52);
  height: rem(52);
  z-index: 999;
}
