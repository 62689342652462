@import "../../../styles/app.scss";

.ats-brand-colors {
  display: flex;
  flex-direction: column;

  &__item-wrapper {
    @include font-base($font-source-sans, Normal, rem(28));
    color: $survey-grey02100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(32);

    .selected {
      @include font-base($font-source-sans, Bold, rem(36));
      color: $seity-green;
    }

    span {
      cursor: pointer;
      margin: rem(0) rem(15);
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: rem(32);
  }

  &__image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: max-content;
    }

    span {
      @include font-base($font-source-sans, Normal, rem(18));
      font-weight: 600;
      color: $seity-green;
      margin-top: rem(8);
    }
  }

  &__section-container {
    margin-bottom: rem(50);
    .border {
      border-bottom: 2px solid $seity-green;
      background-color: $seity-green;
      width: rem(32);
      margin-top: rem(24);
      margin-bottom: rem(34.5);
    }
  }
  &__container-color-list {
    display: flex;
    justify-content: space-around;
  }

  &__section-title {
    color: $seity-dark-green;
    @include font-base($font-source-serif, Normal, rem(36));
  }

  .ats-brand-colors-text {
    color: $ats-brand-text;
    line-height: rem(32);
    margin-bottom: rem(50);
    @include font-base($font-source-sans, Light, rem(24));
    font-weight: 400;
  }
}
