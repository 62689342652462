@import "../../../styles/app.scss";

.survey-button {
    background-color: $seity-teal !important;
    color: $seity-cream !important;
    padding: 0 rem(33);
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    @include font-base($font-source-sans, Light);
    font-size: rem(20);
    cursor: pointer !important;
    transition-duration: 0.4s !important;
    border: rem(2) solid $seity-teal !important;
    border-radius: rem(10) !important;
    line-height: rem(22) !important;
    height: rem(48);
    @include font-base($font-source-sans, Normal, rem(20));
    &:hover:enabled {
        opacity: 0.7;
    }
    &__cancel {
        @extend .seity-button;
        background-color:  $seity-cream !important;
        color: $seity-teal !important;
        border: rem(2) solid $seity-teal !important;
      }
    &__dark {
        @extend .seity-button;
        background-color:  $seity-green !important;
        color: $seity-cream !important;
        border: rem(2) solid $seity-cream !important;
    }
    &__round {
        border-radius: rem(24) !important;
    }
    &__ats-round {
        border-radius: rem(4) !important;
    }
    &__size-mid {
        height: rem(34);
        padding: 0 rem(24);
        font-size: rem(16);
    }
    &__size-mid-ats {
        height: rem(39);
        padding: 0 rem(16);
        font-size: rem(18);
    }
    &__text {
        border-color: transparent !important;
        background-color: transparent !important;
        color: #56A3A6 !important;
    }
    &:disabled {
        background: rgba(86, 163, 166, 0.4) !important;
        border: rgba(86, 163, 166, 0.4) !important;
        cursor: default !important;
    }
}

.btn-block {
    background-color: #FFF8EB !important;
    border: solid 2px #56A3A6 !important;
    color: #56A3A6 !important;
}

.seity-button:disabled {
    color: #56A3A6 !important;
    background: rgba(86, 163, 166, 0.4) !important;
    border: none;
}

// Loading animation style
.dot-pulse {
    position: relative;
    left: -9999px;
    margin-top: rem(15);
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $seity-cream;
    color: $seity-cream;
    box-shadow: 9999px 0 0 -5px $seity-cream;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $seity-cream;
    color: $seity-cream;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px $seity-cream;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px $seity-cream;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px $seity-cream;
    }
    30% {
        box-shadow: 9984px 0 0 2px $seity-cream;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px $seity-cream;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px $seity-cream;
    }
    30% {
        box-shadow: 9999px 0 0 2px $seity-cream;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px $seity-cream;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px $seity-cream;
    }
    30% {
        box-shadow: 10014px 0 0 2px $seity-cream;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px $seity-cream;
    }
}
