@import "../../styles/app.scss";

ul {
  margin: 0;
  padding: 0;
}
.seity_header {
  width: 100%;
  background-color: $seity-grey01100;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom-left-radius: 2rem;
  @include header-height;
}
.seity-header__bg {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}
.seity-header__main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.seity-header__main__center {
  display: flex;
  padding: 0 rem(20);
  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    li {
      display: inline;
      span {
        @include font-base($font-source-sans, Bold);
        padding: rem(10) rem(25);
        margin-right: rem(10);
        font-size: rem(17);
        display: block;
        color: $seity-cream;
        border-width: rem(2);
        border-right: 1px solid $seity-cream;
      }
      a {
        color: $seity-cream;
        padding: rem(10) rem(20);
        text-decoration: none;
        @include header-menu-item;
        &:hover {
          background-color: $seity-grey011000dark;
          border-radius: rem(8);
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
.seity-header__main__right {
  display: flex;
  margin-left: auto;
  padding: 0 rem(20) 0 rem(50);
  align-items: center;
  background-color: $seity-dark-green;
  border-bottom-left-radius: rem(40);
  @include header-menu-item;
  .download-button {
    margin-left: rem(10);
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.gad-tooltip {
  &-item {
    height: rem(39);
    font: rem(16) $font-source-sans !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 rem(16);
    &:hover {
      cursor: pointer;
      opacity: 0.8;
      color: $seity-black;
      background-color: $seity-white !important;
    }
  }
}
