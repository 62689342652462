@mixin font-base($font-family, $font-style, $font-size: 18px) {
  font-family: $font-family + " " + $font-style;
  margin-top: 0;
  font-size: $font-size;
}

@mixin header-height {
  height: $rem-mobile-header-height;
  @include respond-to(tablet) {
    height: $rem-desktop-header-height;
  }
  @include respond-to(desktop) {
    height: $rem-desktop-header-height;
  }
}

@mixin main-margin-top {
  margin-top: $rem-mobile-header-height;
  @include respond-to(tablet) {
    margin-top: $rem-desktop-header-height;
  }
  @include respond-to(desktop) {
    margin-top: $rem-desktop-header-height;
  }
}

@mixin main-width {
  width: 100%;
  height: 100%;
}

@mixin main-min-height {
  min-height: calc(100vh - 4rem);
  @include respond-to(tablet) {
    min-height: calc(100vh - 4.6875rem);
  }
  @include respond-to(desktop) {
    min-height: calc(100vh - 4.6875rem);
  }
}

@mixin header-menu-item() {
  font-family: 'Source Serif Pro';
  font-weight: bold;
  font-size: rem(18);
  color: $seity-cream;
}

@mixin header-menu-back() {
  @include font-base($font-source-sans, Bold);
  color: $seity-cream;
  font-size: rem(20);
}

@mixin sans-font($weight, $size, $color) {
  @include font-base($font-source-sans, Bold);
}