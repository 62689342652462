@import "../../../../styles/app.scss";

.survey-question {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: rem(2) 0;
  .question-content {
    padding: rem(40) 0 rem(122);
    flex: 1;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    list-style: none;
    li {
      margin-bottom: rem(32);
      &:last-child {
        margin-bottom: rem(24);
        text-align: center;
      }
      .randomize-questions {
        margin-left: rem(40);
      }
    }
    li.switch-wrap {
      display: flex;
    }
    .question-item {
      margin-top: rem(32);
      border: 2px solid $survey-bg-section;
      &__error {
        border: 2px solid $survey-orange;
      }
    }
  }
  .question-footer {
    display: flex;
    align-items: center;
    padding: rem(24) rem(104);
    width: 100%;
    height: rem(98);
    border-top: 2px solid rgba(0, 0, 0, 0.35);
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $seity-cream;
    p {
      color: $survey-orange;
      margin-right: auto;
    }
    .cancel-button {
      margin-left: auto;
    }
  }
  .question-header {
    display: flex;
    align-items: center;
    &__right {
      margin-left: auto;
      display: flex;
      align-items: center;
      span {
        margin-left: rem(13);
        margin-right: rem(20);
        display: inline;
        @include font-base($font-source-sans, Normal);
        font-size: rem(16);
        color: $survey-grey02100;
      }
    }
  }
  .stepper-container {
    display: flex;
    align-items: center;
    span {
      width: rem(32);
      height: rem(32);
      text-align: center;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      border-radius: rem(16);
      background-color: $survey-teal-med;
      color: white;
      @include font-base($font-source-sans, Normal);
      margin-right: rem(16);
    }
    span.completed {
      background-color: $survey-teal-dark;
    }
    span.unfocused {
      background-color: $survey-gray;
    }
    p {
      color: $survey-black;
      @include font-base($font-source-sans, Normal);
      font-size: rem(16);
    }
    p.unfocused {
      color: $survey-gray;
    }
    div {
      background-color: $survey-gray;
      width: rem(25);
      height: rem(1);
      margin: 0 rem(16);
    }
  }
}
.sortableHelper {
  z-index: 1000;
}

.survey-modal-error {
  z-index: 100;
}

.survey-confirm-cancel {
  padding: 0 rem(10);
  h3 {
    text-align: center;
    @include font-base($font-source-sans, Normal);
    font-size: rem(22);
    color: $survey-black;
  }
  div {
    display: flex;
    margin-top: rem(50);
    justify-content: center;
    button {
      margin: 0 rem(10);
    }
  }
}
