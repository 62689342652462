@import "../../../styles/app.scss";

.seity-analytic-button {
  background-color: $seity-teal !important;
  color: $seity-cream !important;
  padding: rem(13) rem(33);
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: rem(20) !important;
  font-family: $font-source-sans !important;
  cursor: pointer !important;
  transition-duration: 0.4s !important;
  border: rem(2) solid $seity-teal !important;
  border-radius: rem(36) !important;
  line-height: rem(22) !important;
  &__cancel {
    @extend .seity-analytic-button;
    background-color:  $seity-cream !important;
    color: $seity-teal !important;
    border: rem(2) solid $seity-teal !important;
  }
  &__dark {
    @extend .seity-analytic-button;
    background-color:  $seity-green !important;
    color: $seity-cream !important;
    border: rem(2) solid $seity-cream !important;
  }
}

.btn-block {
  background-color: #FFF8EB !important;
  border: solid 2px #56A3A6 !important;
  color: #56A3A6 !important;
}

.seity-analytic-button:disabled {
  color: #56A3A6 !important;
  background: rgba(86, 163, 166, 0.4) !important;
  border: none;
}
