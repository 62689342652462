$seity-green: #41625e;
$seity-dark-green: #314947;
$seity-cream: #fff8eb;
$seity-pink: #f08080;
$seity-orange: #f75a3b;
$seity-world: #2f696c;
$seity-teal: #56a3a6;
$seity-mind: #9ac8ca;
$seity-spirit: #bbdadb;
$seity-grey: #c1c4ba;
$seity-grey01100: #5c6870;
$seity-grey011000dark: #454a4e;
$seity-light-grey: rgba(190, 191, 186, 0.5);
$seity-grey02100: #f1ebe0;
$seity-dark-grey: #e4dfd5;
$seity-cream01100: #fff8eb;
$seity-grey02100: #f1ebe0;
$seity-white: #fff;
$seity-black: #000;
$seity-light-black: #343b43;
$seity-middle-teal: #539fa3;

$survey-bg: #e4dfd5;
$survey-dark-gray: #b7b5ab;
$survey-grey02100: #495459;
$survey-greyc5c3bc: #c5c3bc;
$survey-bg-section: #c4d3cd;
$survey-orange: #dc2f02;
$survey-teal-med: #539FA3;
$survey-black: #343B43;
$survey-gray: #C1C7CD;
$survey-teal-dark: #4F7E81;

$ats-banner-bg: #dcd9d3;
$ats-lifestyle-bg: #dcd9d352;

$ats-bg: #f4f4f4;
$ats-brand-text: #64748b;

$wbn-dark-charcoal: #333333;
$wbn-whitesmoke: #f5f5f5;
