@import "src/styles/app.scss";

.ats-outcomecategorieselement {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  background-color: $ats-lifestyle-bg;
  padding: rem(32) rem(61);
  border-bottom: rem(1) solid $seity-grey01100;

  &.shrink {
    max-height: rem(70);
    border-bottom: rem(1) solid $survey-grey02100;
  }
  &__header {
    display: flex;
    align-items: center;
  }
  h2 {
    margin-top: rem(10) !important;
    color: $seity-dark-green;
    margin-left: rem(-0.4);
    @include font-base($font-source-serif, Bold, rem(30));
  }
  h5 {
    @include font-base($font-source-sans, Bold, rem(20));
    color: $survey-grey02100;
    margin-bottom: rem(16);
  }

  &__content-wrap {
    &__container {
      margin: rem(16) 0 0 0;
    }
  }
  &__caption {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(40);
    .btn-copy {
      margin-left: auto;
      margin-top: -5px;
    }
    &__description {
      @include font-base($font-source-sans, Normal, rem(18));
      color: $survey-grey02100;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(24);
    }
  }
  .outcomecategorieselement-icon {
    display: flex;
    margin-bottom: rem(29);
    align-items: center;
    img {
      margin-right: auto;
    }
  }
}
