@import '../../../styles/app.scss';

.gv-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: rem(45) rem(100);

  @include respond-to(mobile) {
    padding: rem(30) rem(40);
  }
}

.gv-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &-left {
    h1 {
      font: rem(40) $font-source-dm-seif;
      color: $seity-dark-green;
    }

    h4 {
      font: rem(20) $font-source-sans;
      color: $seity-black;
      margin-top: rem(20);
    }
  }
}

.gv-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: rem(20);
  
  &-loader {
    width: rem(100);
  }
}

.gv-item {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: rem(24) rem(50);
    border-bottom: rem(1) solid $seity-grey;
  }

  &-left {
    display: flex;
    align-items: center;
    font: rem(18) $font-source-sans;
  }

  &-center {
    display: flex;
    align-items: center;
    font: rem(18) $font-source-sans;
  }

  &-right {
    display: flex;
    align-items: center;  
  }
}

.gv-create-btn {
  border: none;
  color: $seity-white;
  background-color: $seity-teal;
  box-shadow: 0 0 rem(1) rgba(40, 41, 61, 0.04), 0 rem(2) rem(4) rgba(96, 97, 112, 0.16);
  border-radius: rem(10);
  width: rem(206);
  height: rem(56);
  font: rem(20) $font-source-sans;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.gv-add-btn {
  border: none;
  background-color: $seity-cream;
  padding-left: rem(20);
  padding-top: rem(5);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.gv-edit-btn {
  border: none;
  background-color: $seity-cream;
  padding-left: rem(20);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.gv-delete-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 rem(15);
  border: none;
  color: $seity-white;
  background-color: $seity-green;
  box-shadow: 0 0 rem(1) rgba(40, 41, 61, 0.04), 0 rem(2) rem(4) rgba(96, 97, 112, 0.16);
  border-radius: rem(10);
  width: rem(95);
  height: rem(36);
  font: rem(14) $font-source-sans;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.gv-plus {
  margin-right: rem(10);
}

.gad-wrapper {
  width: rem(570);
  display: flex;
  flex-direction: column;
  outline: none;
}

.gad-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: rem(27) $font-source-dm-seif;
}

.gad-main {
  width: 100%;
  margin: rem(32) 0;
}

.gad-input-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  font: rem(16) $font-source-sans;
  color: $seity-green;
}

.gad-input {
  width: 100%;
  min-width: rem(490);
  height: rem(48);
  border: rem(1) solid $seity-grey;
  border-radius: rem(4);
  font: rem(18) $font-source-sans;
  margin-top: rem(5);
  padding: 0 rem(10);
}

.gad-footer {
  display: flex;
  align-self: flex-end;
}

.gad-close-btn {
  border: none;
  background-color: $seity-white;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.gad-cancel-btn {
  border: none;
  background-color: $seity-white;
  font: rem(18) $font-source-sans;
  padding: rem(16);
  margin-right: rem(10);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.gad-create-btn {
  border: none;
  background-color: $seity-teal;
  font: rem(18) $font-source-sans;
  color: $seity-white;
  border-radius: rem(10);
  padding: rem(16);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:disabled {
    background-color: $seity-grey;
  }
}

.gad-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $seity-white;
  border-radius: rem(10);
  padding: rem(24) rem(40) rem(32) rem(40);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: rem(200);
  min-height: rem(200);
  height: auto;
  z-index: 20;
}

.gad-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.gad-main {
  width: 100%;
  height: 100%;
  
  &-upper {
    margin-bottom: rem(40);
    border: rem(1) solid $seity-grey;
    border-radius: rem(4);
    height: rem(48);
    width: 100%;
    padding: 0 0 0 rem(19);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-right: rem(10);
    }
    input {
      border: none;
      font: rem(18) $font-source-sans;
      outline: none;
      width: 80%;
    }
    button {
      outline: none;
      border: none;
      background-color: $seity-white;
      font: rem(18) $font-source-sans;
      color: $seity-teal;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-lower {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-left {
      width: 48%;
    }
    &-right {
      width: 48%;
    }
  }
}

.gad-member {
  &-container {
    width: 100%;
    height: rem(291);
    border-radius: rem(8);
    border: rem(1) solid $seity-grey;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    margin-top: rem(10);
  }
  &-item {
    width: 90%;
    min-height: rem(67);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font: rem(15) $font-source-sans;
    color: $seity-light-black;
    outline: none;
    border: none;
    border-bottom: rem(1) solid $seity-grey;
    background-color: $seity-white;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    img {
      padding-top: rem(5);
      margin-right: rem(10);
    }
  }
  &-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: rem(5);
    font: rem(14) $font-source-sans;

    button {
      outline: none;
      border: none;
      background-color: $seity-white;
      font: rem(15) $font-source-sans-bold;
      color: $seity-teal;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
